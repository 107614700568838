var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.twilio.inCall && !_vm.twilio.callAlert
    ? _c(
        "v-container",
        { staticClass: "wrapper px-2 py-2" },
        [
          _c("v-layout", { attrs: { row: "" } }, [
            _c("h5", [_vm._v("Current Call")]),
          ]),
          _c(
            "v-layout",
            { staticClass: "px-2 py-2", attrs: { row: "" } },
            [
              _c("v-layout", { attrs: { column: "" } }, [
                _c("div", { staticClass: "text-right mr-2" }, [
                  _vm._v("Phone Number"),
                ]),
                _c("div", { staticClass: "text-right mr-2" }, [
                  _vm._v("Policy"),
                ]),
              ]),
              _c("v-layout", { attrs: { column: "" } }, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.twilio.callingLabel)),
                ]),
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.twilio.callPolicyName)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }