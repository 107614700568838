var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "v-speed-dial",
        {
          staticStyle: { bottom: "45px", right: "45px" },
          attrs: { absolute: true, "open-on-hover": false },
          model: {
            value: _vm.changeSpeedDialIcon,
            callback: function ($$v) {
              _vm.changeSpeedDialIcon = $$v
            },
            expression: "changeSpeedDialIcon",
          },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "btn-floating btn-large",
              attrs: {
                slot: "activator",
                color: "secondary darken-1",
                dark: "",
                fab: "",
                hover: "",
              },
              slot: "activator",
              model: {
                value: _vm.changeSpeedDialIcon,
                callback: function ($$v) {
                  _vm.changeSpeedDialIcon = $$v
                },
                expression: "changeSpeedDialIcon",
              },
            },
            [_c("v-icon", [_vm._v("add")]), _c("v-icon", [_vm._v("close")])],
            1
          ),
          _vm._l(_vm.items, function (item) {
            return _c(
              "v-tooltip",
              {
                key: item.id,
                staticClass: "top-tooltip",
                attrs: { left: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                style: _vm.getBackgroundColourStyleFor(item),
                                attrs: { fab: "", dark: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDialog(item)
                                  },
                                },
                              },
                              on
                            ),
                            [
                              item.id === "US"
                                ? _c("img", {
                                    staticClass: "drainage-img",
                                    attrs: {
                                      src: "/img/drainage.png",
                                      alt: "",
                                    },
                                  })
                                : item.id === "SI"
                                ? _c("img", {
                                    staticClass: "si-img",
                                    attrs: { src: "/img/si.png", alt: "" },
                                  })
                                : _c(
                                    "em",
                                    { staticClass: "btn-floating" },
                                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                    1
                                  ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [_c("span", [_vm._v(_vm._s(item.title))])]
            )
          }),
        ],
        2
      ),
      _vm.showNewHEJobDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.showNewHEJobDialog,
                persistent: "",
                "max-width": "1000px",
                "content-class": _vm.isDuplicateJobOpen
                  ? "hide-wizard-dialog-wrapper h-100"
                  : "h-100",
              },
            },
            [
              _c("iframe", {
                ref: "NewHEJobWrapper",
                staticClass: "he-wizard-frame",
                attrs: { id: "NewHEJobWrapper", src: _vm.heWizardUrl },
                on: {
                  load: function ($event) {
                    return _vm.sendInitialData($event.target)
                  },
                },
              }),
            ]
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "wizard-dialog v-dialog--scrollable",
            persistent: "",
            "max-width": "820px",
          },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [
                    _vm._v(_vm._s(_vm.dialogToolbarTitle)),
                  ]),
                  !_vm.showUploadDocument
                    ? _c(
                        "v-menu",
                        {
                          attrs: { bottom: "", left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        { attrs: { icon: "", dark: "" } },
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("more_vert")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1568013183
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.items, function (item) {
                              return _c(
                                "v-list-tile",
                                {
                                  key: item.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDialog(item)
                                    },
                                  },
                                },
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                {
                  ref: _vm.AddDialogContentRef,
                  staticClass: "add-dialog-content scroll-content-dialog pb-0",
                  style: _vm.dialogContentDynamicStyle,
                },
                [
                  _c("AddDrainageClaimWizard", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showDrainageClaimWizard,
                        expression: "showDrainageClaimWizard",
                      },
                    ],
                    ref: _vm.DrainageChildRef,
                    attrs: { "wizard-visible": _vm.showDrainageClaimWizard },
                    on: {
                      updateContentHeight: _vm.updateContentHeight,
                      wizardPageChanged: _vm.wizardPageChanged,
                      wizardComplete: _vm.closeDialog,
                      wizardBackEndOperation: _vm.wizardBackEndOperation,
                      showUploadDocument: _vm.onShowUploadDocument,
                    },
                  }),
                  _c("AddSIClaimWizard", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showSIClaimWizard,
                        expression: "showSIClaimWizard",
                      },
                    ],
                    ref: _vm.SIChildRef,
                    attrs: { "wizard-visible": _vm.showSIClaimWizard },
                    on: {
                      updateContentHeight: _vm.updateContentHeight,
                      wizardPageChanged: _vm.wizardPageChanged,
                      wizardComplete: _vm.closeDialog,
                      wizardBackEndOperation: _vm.wizardBackEndOperation,
                      showUploadDocument: _vm.onShowUploadDocument,
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-actions", { staticClass: "px-3" }, [
                !_vm.showUploadDocument
                  ? _c(
                      "div",
                      { staticClass: "btn-wrap" },
                      [
                        (_vm.showDrainageClaimWizard &&
                          _vm.drainageWizardStep === 1) ||
                        (_vm.showSIClaimWizard && _vm.siWizardStep === 1) ||
                        (_vm.showSIClaimWizard && _vm.siWizardStep === 2)
                          ? _c(
                              "v-btn",
                              {
                                attrs: { flat: "" },
                                on: { click: _vm.clearHEWizardForm },
                              },
                              [_vm._v(" Clear Form ")]
                            )
                          : _vm._e(),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "" },
                            on: { click: _vm.closeDialog },
                          },
                          [_vm._v("Close")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-0",
                            attrs: {
                              color: "primary",
                              loading: _vm.isLoading,
                              disabled: _vm.isLoading,
                            },
                            on: { click: _vm.nextClicked },
                          },
                          [_vm._v(" " + _vm._s(_vm.nextButtonText) + " ")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "btn-wrap" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-0",
                            attrs: {
                              disabled:
                                _vm.isLoading || !_vm.isAnyDocumentsUploaded,
                              loading: _vm.isLoading,
                              color: "primary",
                            },
                            on: { click: _vm.uploadDocumentClicked },
                          },
                          [_vm._v(" Upload ")]
                        ),
                      ],
                      1
                    ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }