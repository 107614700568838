var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _vm.isLoading
        ? _c("v-progress-circular", {
            style: _vm.getLoaderStyle(70),
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "white pa-3" },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("h3", [_vm._v("Engineer Visit Details")]),
                  ]),
                  !!_vm.contractorRatesStore.ratePackageId
                    ? _c("v-flex", [
                        _c(
                          "span",
                          { staticClass: "ml-1" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "ma-0 view-contractor-rate",
                                                attrs: { icon: "", small: "" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    _vm.contractorRatesStore.show = true
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "primary--text view-rate-icon",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("rate_review")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1967592990
                                ),
                              },
                              [_c("span", [_vm._v("View Rate Detail")])]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mt-2 mb-3" }),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "d-inline-flex": "" } },
                [
                  _c("v-flex", [
                    _c("div", { staticClass: "engineer-img" }, [
                      _c("img", {
                        staticClass: "profile-img",
                        attrs: { src: _vm.engineer.profileImageUrl },
                      }),
                    ]),
                  ]),
                  _c("v-flex", { attrs: { "px-3": "" } }, [
                    _vm.engineer && _vm.engineer.firstName
                      ? _c("h2", { staticClass: "subheading" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.engineer.firstName) +
                              " " +
                              _vm._s(_vm.engineer.lastName) +
                              " "
                          ),
                        ])
                      : _c("h2", { staticClass: "subheading" }, [
                          _vm._v("Engineer"),
                        ]),
                    _c("p", { staticClass: "mb-1" }, [
                      _c(
                        "b",
                        { staticClass: "primary--text d-inline-block mt-2" },
                        [_vm._v(_vm._s(_vm.engineerType))]
                      ),
                    ]),
                    _vm.canSeeEngineerPhoneNumber
                      ? _c("p", { staticClass: "mb-0" }, [
                          _c(
                            "span",
                            { staticClass: "grey--text text--darken-1" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "grey--text text--darken-1",
                                  attrs: { small: "", color: "grey" },
                                },
                                [_vm._v("call")]
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.engineer.contactNumber) + " "
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm.engineerVisitDetail.companyName
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "grey--text text--darken-1 pt-1 d-inline-block",
                            },
                            [
                              _c("img", {
                                staticClass: "contractor-icon",
                                attrs: { src: "/img/contractor-icon.svg" },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.engineerVisitDetail.companyName) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
              _vm.engineerVisitDetail &&
              _vm.engineerVisitDetail.isEngineerReAttend &&
              _vm.engineerVisitDetail.visitNumber > 1 &&
              _vm.jobType === "SI"
                ? _c(
                    "div",
                    [
                      _c("v-divider", { staticClass: "my-2" }),
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "", "mt-2": "" } },
                        [
                          _c("v-flex", [
                            _c("h3", [_vm._v("Engineer Return Visit")]),
                            _c("div", { staticClass: "mt-2" }, [
                              _c("div", { staticClass: "mb-1" }, [
                                _c("b", [_vm._v("Reason:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.engineerVisitDetail
                                        .siReturnVisitReason
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "mb-1" }, [
                                _c("b", [_vm._v("Note:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.engineerVisitDetail.siReturnVisitNote
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "mb-1" }, [
                                _c("b", [_vm._v("Total Visits:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.engineerVisitDetail.visitNumber
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.engineerVisitDetail.status ===
              _vm.engineerJobVisitStatus[_vm.engineerJobVisitStatus.RunningLate]
                ? _c("v-divider", { staticClass: "my-2" })
                : _vm._e(),
              _vm.engineerVisitDetail.status ===
              _vm.engineerJobVisitStatus[_vm.engineerJobVisitStatus.RunningLate]
                ? _c(
                    "v-layout",
                    [
                      _c("v-flex", [
                        _c("h3", [_vm._v("Engineer Running Late")]),
                        _c("div", { staticClass: "mt-2" }, [
                          _c(
                            "div",
                            { staticClass: "mb-1" },
                            [
                              _c("v-icon", [_vm._v("date_range")]),
                              _vm._v("   "),
                              _c("b", [_vm._v("ETA From:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getFormattedDate(
                                      _vm.engineerVisitDetail.etaFrom
                                    )
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-1" },
                            [
                              _c("v-icon", [_vm._v("date_range")]),
                              _vm._v("   "),
                              _c("b", [_vm._v("ETA To:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getFormattedDate(
                                      _vm.engineerVisitDetail.etaTo
                                    )
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(_vm._s(_vm.engineerVisitDetail.description)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showEngineerLocation
                ? _c("v-divider", { staticClass: "my-2" })
                : _vm._e(),
              _vm.showEngineerLocation
                ? _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "mt-2": "" } },
                    [
                      _vm.engineerVisitDetail.status ===
                      _vm.engineerJobVisitStatus[
                        _vm.engineerJobVisitStatus.OnTheWay
                      ]
                        ? _c("v-flex", { attrs: { lg6: "", xs12: "" } }, [
                            _c("h3", [_vm._v("Engineer On The Way")]),
                            _c("div", { staticClass: "mt-2 grey--text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getFormattedDate(
                                      _vm.engineerVisitDetail.processAt
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.engineerVisitDetail.status ===
                      _vm.engineerJobVisitStatus[
                        _vm.engineerJobVisitStatus.PolicyHolderAbsent
                      ]
                        ? _c(
                            "v-flex",
                            { attrs: { lg6: "", xs12: "" } },
                            [
                              _c("v-checkbox", {
                                staticClass:
                                  "customer-contact-chkbox full-width",
                                attrs: {
                                  readonly: true,
                                  name: "isCustomerContacted",
                                  "hide-details": true,
                                  label: "Customer Contacted",
                                  color: "primary",
                                },
                                model: {
                                  value:
                                    _vm.engineerVisitDetail.isCustomerContacted,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.engineerVisitDetail,
                                      "isCustomerContacted",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "engineerVisitDetail.isCustomerContacted",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            "px-3": "",
                            "text-xs-right": "",
                            lg6: "",
                            xs12: "",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "ma-0 btn-padding-zero engineer-location",
                              attrs: {
                                flat: "",
                                small: "",
                                color: "secondary",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showLiveLocation = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.engineerVisitDetail.status ===
                                      _vm.engineerJobVisitStatus[
                                        _vm.engineerJobVisitStatus.OnTheWay
                                      ]
                                      ? "Live"
                                      : "Visit"
                                  ) +
                                  " location   "
                              ),
                              _c("v-icon", [_vm._v("place")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.engineerVisitDetail.status ===
              _vm.engineerJobVisitStatus[_vm.engineerJobVisitStatus.JobStarted]
                ? _c("v-divider", { staticClass: "my-2" })
                : _vm._e(),
              _vm.engineerVisitDetail.status ===
              _vm.engineerJobVisitStatus[_vm.engineerJobVisitStatus.JobStarted]
                ? _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "mt-2": "" } },
                    [
                      _c("v-flex", [
                        _c("h3", [_vm._v("Engineer On Site")]),
                        _c("div", { staticClass: "mt-2" }, [
                          _vm._v(_vm._s(_vm.engineerVisitDetail.address)),
                        ]),
                        _c("div", { staticClass: "mt-2 grey--text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getFormattedDate(
                                  _vm.engineerVisitDetail.processAt
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                [
                  _vm.engineerVisitDetail.status ===
                    _vm.engineerJobVisitStatus[
                      _vm.engineerJobVisitStatus.Pending
                    ] ||
                  _vm.engineerVisitDetail.status ===
                    _vm.engineerJobVisitStatus[
                      _vm.engineerJobVisitStatus.Accepted
                    ] ||
                  _vm.engineerVisitDetail.status ===
                    _vm.engineerJobVisitStatus[
                      _vm.engineerJobVisitStatus.OnTheWay
                    ] ||
                  _vm.engineerVisitDetail.status ===
                    _vm.engineerJobVisitStatus[
                      _vm.engineerJobVisitStatus.OnSite
                    ] ||
                  _vm.engineerVisitDetail.status ===
                    _vm.engineerJobVisitStatus[
                      _vm.engineerJobVisitStatus.RunningLate
                    ]
                    ? _c(
                        "v-flex",
                        { staticClass: "text-xs-right", attrs: { xs12: "" } },
                        [
                          _c("v-divider", { staticClass: "my-2" }),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.changeVisitStartTime },
                            },
                            [_vm._v("Add Visit Start Time")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              !(
                _vm.engineerVisitDetail.status ===
                  _vm.engineerJobVisitStatus[
                    _vm.engineerJobVisitStatus.Pending
                  ] ||
                _vm.engineerVisitDetail.status ===
                  _vm.engineerJobVisitStatus[
                    _vm.engineerJobVisitStatus.Accepted
                  ]
              )
                ? _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _vm.policyHolderNotPresentPicture.length > 0
                            ? _c("v-divider", { staticClass: "my-2" })
                            : _vm._e(),
                          _vm.policyHolderNotPresentPicture.length > 0
                            ? _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "", "mt-2": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("PictureUpload", {
                                        attrs: {
                                          "job-id": _vm.jobId,
                                          "picture-list":
                                            _vm.policyHolderNotPresentPicture,
                                          "uploaded-by": "PolicyHolderMissing",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.policyHolderNotPresentPicture.length > 0
                            ? _c("v-divider", { staticClass: "my-2" })
                            : _vm._e(),
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "mt-2": "",
                                "mb-3": "",
                              },
                            },
                            [
                              _vm.engineerVisitDetail.visitNumber &&
                              _vm.engineerVisitDetail.visitNumber > 1
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "que-checkbox full-width",
                                        attrs: {
                                          disabled:
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Abandoned
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Cancelled
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Rejected
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Completed
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .ReAssigned
                                              ],
                                          name: "CanChargeCallout",
                                          "hide-details": true,
                                          label: "Can Charge Callout",
                                          color: "primary",
                                        },
                                        on: {
                                          change: _vm.canChargeCalloutChange,
                                        },
                                        model: {
                                          value:
                                            _vm.engineerVisitDetail
                                              .canChargeCallout,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.engineerVisitDetail,
                                              "canChargeCallout",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "engineerVisitDetail.canChargeCallout",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.RunningLate
                                ] &&
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.OnTheWay
                                ] &&
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.OnSite
                                ]
                                ? _c(
                                    "v-flex",
                                    { attrs: { lg7: "", md12: "" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "que-checkbox full-width",
                                        attrs: {
                                          disabled:
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Abandoned
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Cancelled
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Rejected
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Completed
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .ReAssigned
                                              ],
                                          name: "HealthAndSafetyPolicy",
                                          "hide-details": true,
                                          label:
                                            "Health And Safety Checks Completed",
                                          color: "primary",
                                        },
                                        on: {
                                          change:
                                            _vm.onHealthAndSafetyPolicyChange,
                                        },
                                        model: {
                                          value:
                                            _vm.engineerVisitDetail
                                              .isCheckForHealthAndSafety,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.engineerVisitDetail,
                                              "isCheckForHealthAndSafety",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "engineerVisitDetail.isCheckForHealthAndSafety",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.RunningLate
                                ] &&
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.OnTheWay
                                ] &&
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.OnSite
                                ] &&
                              _vm.getVisitHealthAndSafetyDetails
                                ? _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        lg5: "",
                                        md12: "",
                                        "text-lg-right": "",
                                        "text-md-left": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-0 mr-0 mt-1 pa-0",
                                          attrs: { flat: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.ShowHealthAndSafetyCheckDetails.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("picture_as_pdf"),
                                          ]),
                                          _vm._v("   Show H&S Details "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.RunningLate
                                ] &&
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.OnTheWay
                                ] &&
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.OnSite
                                ] &&
                              _vm.getBoilerBreakdownQA
                                ? _c(
                                    "v-flex",
                                    { attrs: { lg7: "", md12: "" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "que-checkbox full-width",
                                        attrs: {
                                          disabled:
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Abandoned
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Cancelled
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Rejected
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Completed
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .ReAssigned
                                              ],
                                          name: "GasSafetyPolicy",
                                          "hide-details": true,
                                          label: "Gas Safety Checks Completed",
                                          color: "primary",
                                        },
                                        on: {
                                          change: _vm.onGasSafetyCheckChange,
                                        },
                                        model: {
                                          value:
                                            _vm.engineerVisitDetail
                                              .isGasSafetyCheckComplete,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.engineerVisitDetail,
                                              "isGasSafetyCheckComplete",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "engineerVisitDetail.isGasSafetyCheckComplete",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.RunningLate
                                ] &&
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.OnTheWay
                                ] &&
                              _vm.engineerVisitDetail.status !==
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.OnSite
                                ] &&
                              _vm.getBoilerBreakdownQA &&
                              _vm.getBoilerBreakdownQA.gasSafetyDetails
                                ? _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        lg5: "",
                                        md12: "",
                                        "text-lg-right": "",
                                        "text-md-left": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-0 mr-0 mt-1 pa-0",
                                          attrs: { flat: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              _vm.showGasSafetyCheckDetails = true
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("picture_as_pdf"),
                                          ]),
                                          _vm._v("   Show Gas Safety Details "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.engineerBeforeJobPictureList.length > 0
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mt-3": "" } },
                                    [
                                      _c("PictureUpload", {
                                        attrs: {
                                          "job-id": _vm.jobId,
                                          "picture-list":
                                            _vm.engineerBeforeJobPictureList,
                                          "uploaded-by": "EngineerBeforeJob",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.getBoilerBreakdownQA
                            ? _c("v-divider", { staticClass: "my-2" })
                            : _vm._e(),
                          _vm.getBoilerBreakdownQA
                            ? _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "", "mt-2": "" } },
                                [
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("h3", [
                                      _vm._v(
                                        "Boiler Breakdown Question & Answer"
                                      ),
                                    ]),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getBoilerBreakdownQA
                                              .modelQuestion
                                          ) + ":"
                                        ),
                                      ]),
                                      _vm.getBoilerBreakdownQA.modelAnswer
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getBoilerBreakdownQA
                                                    .modelAnswer
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("span", [_vm._v("-")]),
                                    ]),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getBoilerBreakdownQA
                                              .makeQuestion
                                          ) + ":"
                                        ),
                                      ]),
                                      _vm.getBoilerBreakdownQA.makeAnswer
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getBoilerBreakdownQA
                                                    .makeAnswer
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("span", [_vm._v("-")]),
                                    ]),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getBoilerBreakdownQA
                                              .gcNumberQuestion
                                          ) + ":"
                                        ),
                                      ]),
                                      _vm.getBoilerBreakdownQA.gcNumberAnswer
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getBoilerBreakdownQA
                                                    .gcNumberAnswer
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("span", [_vm._v("-")]),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.getBoilerBreakdownQA &&
                          _vm.engineerVisitDetail.status !==
                            _vm.engineerJobVisitStatus[
                              _vm.engineerJobVisitStatus.Completed
                            ] &&
                          _vm.engineerVisitDetail.status !==
                            _vm.engineerJobVisitStatus[
                              _vm.engineerJobVisitStatus.Cancelled
                            ] &&
                          _vm.engineerVisitDetail.status !==
                            _vm.engineerJobVisitStatus[
                              _vm.engineerJobVisitStatus.Rejected
                            ] &&
                          _vm.engineerVisitDetail.status !==
                            _vm.engineerJobVisitStatus[
                              _vm.engineerJobVisitStatus.Abandoned
                            ] &&
                          _vm.engineerVisitDetail.status !==
                            _vm.engineerJobVisitStatus[
                              _vm.engineerJobVisitStatus.ReAssigned
                            ]
                            ? _c("v-divider", { staticClass: "my-2" })
                            : _vm._e(),
                          _vm.showAdditionalRequestButton
                            ? _c(
                                "v-layout",
                                { attrs: { "mt-2": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-xs-right",
                                      attrs: { xs12: "" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "ma-0 mr-2 btn-addAdditionalRequest",
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              _vm.additionalRequestDialog = true
                                            },
                                          },
                                        },
                                        [_vm._v(" Additional Request ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.getAdditionalRequestList.length > 0
                            ? _c("v-divider", { staticClass: "my-2" })
                            : _vm._e(),
                          _vm.getAdditionalRequestList.length > 0
                            ? _c("EngineerResourceRequestComponent", {
                                attrs: {
                                  "additional-request-list":
                                    _vm.getAdditionalRequestList,
                                  "job-id": _vm.jobId,
                                  "engineer-visit-detail":
                                    _vm.engineerVisitDetail,
                                  "parent-component": "EngineerVisitPreview",
                                },
                              })
                            : _vm._e(),
                          _vm.engineerDuringJobPictureList.length
                            ? [
                                _c("v-divider", { staticClass: "mt-2 mb-3" }),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "mt-3": "" } },
                                  [
                                    _c("PictureUpload", {
                                      attrs: {
                                        "job-id": _vm.jobId,
                                        "picture-list":
                                          _vm.engineerDuringJobPictureList,
                                        "uploaded-by": "DuringJobPhotos",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm.engineerAfterJobPictureList.length > 0
                            ? _c("v-divider", { staticClass: "mt-2 mb-3" })
                            : _vm._e(),
                          _vm.engineerAfterJobPictureList.length > 0
                            ? _c("PictureUpload", {
                                attrs: {
                                  "job-id": _vm.jobId,
                                  "picture-list":
                                    _vm.engineerAfterJobPictureList,
                                  "uploaded-by": "EngineerAfterJob",
                                },
                              })
                            : _vm._e(),
                          (
                            _vm.engineerVisitDetail.status ===
                            _vm.engineerJobVisitStatus[
                              _vm.engineerJobVisitStatus.Completed
                            ]
                              ? true
                              : _vm.engineerVisitDetail.visitNote
                          )
                            ? _c("v-divider", { staticClass: "my-2" })
                            : _vm._e(),
                          (
                            _vm.engineerVisitDetail.status ===
                            _vm.engineerJobVisitStatus[
                              _vm.engineerJobVisitStatus.Completed
                            ]
                              ? true
                              : _vm.engineerVisitDetail.visitNote
                          )
                            ? _c(
                                "v-layout",
                                { attrs: { "mt-2": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-icon", [_vm._v("event_note")]),
                                      _vm._v("   "),
                                      _c("b", [_vm._v("Site Notes:")]),
                                      _c("pre", { staticClass: "notes" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.engineerVisitDetail.visitNote
                                              ? _vm.engineerVisitDetail
                                                  .visitNote
                                              : "-"
                                          )
                                        ),
                                      ]),
                                      _vm.showEditSiteNoteButton
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "ma-0 name-edit lighten-2 underline edit-site-note-btn",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.openOffSiteTimeDialog(
                                                    _vm.engineerVisitDetail
                                                      .visitCompletedAt
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("edit")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          (_vm.getContractorEtaFrom !== null &&
                            _vm.getContractorEtaTo !== null) ||
                          (_vm.engineerVisitDetail.status !==
                            _vm.engineerJobVisitStatus[
                              _vm.engineerJobVisitStatus.RunningLate
                            ] &&
                            _vm.engineerVisitDetail.status !==
                              _vm.engineerJobVisitStatus[
                                _vm.engineerJobVisitStatus.PolicyHolderAbsent
                              ] &&
                            _vm.engineerVisitDetail.status !==
                              _vm.engineerJobVisitStatus[
                                _vm.engineerJobVisitStatus.OnTheWay
                              ] &&
                            _vm.engineerVisitDetail.status !==
                              _vm.engineerJobVisitStatus[
                                _vm.engineerJobVisitStatus.OnSite
                              ]) ||
                          _vm.healthAndSafetySignatureUrl !== "" ||
                          (_vm.emergency.followOnWorkRequired &&
                            _vm.emergency.followOnDescription !== "")
                            ? _c("v-divider", { staticClass: "my-2" })
                            : _vm._e(),
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "pr-2": "",
                                "customer-signature": "",
                              },
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "mb-2",
                                  class:
                                    _vm.healthAndSafetySignatureUrl === ""
                                      ? "lg12"
                                      : "lg7",
                                },
                                [
                                  _vm.getContractorEtaFrom !== null &&
                                  _vm.getContractorEtaTo !== null
                                    ? _c(
                                        "div",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("v-icon", [_vm._v("date_range")]),
                                          _vm._v("   "),
                                          _c("b", [_vm._v("Original ETA:")]),
                                          _c("span", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.getContractorEtaFrom)
                                              ),
                                            ]),
                                            _c("b", [_vm._v("To")]),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.getContractorEtaTo)
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.engineerVisitDetail
                                    .distanceToCustomerLocation
                                    ? _c(
                                        "div",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("directions_car"),
                                          ]),
                                          _c("label", [
                                            _vm._v("   "),
                                            _c("b", [
                                              _vm._v("Travel Distance:"),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.engineerVisitDetail
                                                    .distanceToCustomerLocation
                                                ) +
                                                " KM "
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.engineerVisitDetail.engineerOnTheWayAt &&
                                  _vm.engineerVisitDetail.engineerOnSiteAt
                                    ? _c(
                                        "div",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("v-icon", [_vm._v("access_time")]),
                                          _c("label", [
                                            _vm._v("   "),
                                            _c("b", [_vm._v("Travel Time:")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getTravelTime(
                                                    _vm.engineerVisitDetail
                                                      .engineerOnTheWayAt,
                                                    _vm.engineerVisitDetail
                                                      .engineerOnSiteAt
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm._v("     "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.engineerVisitDetail.visitStartedAt &&
                                  _vm.engineerVisitDetail.status !==
                                    _vm.engineerJobVisitStatus[
                                      _vm.engineerJobVisitStatus.JobStarted
                                    ] &&
                                  _vm.engineerVisitDetail.status !==
                                    _vm.engineerJobVisitStatus[
                                      _vm.engineerJobVisitStatus.Pending
                                    ] &&
                                  _vm.engineerVisitDetail.status !==
                                    _vm.engineerJobVisitStatus[
                                      _vm.engineerJobVisitStatus.Cancelled
                                    ] &&
                                  _vm.engineerVisitDetail.status !==
                                    _vm.engineerJobVisitStatus[
                                      _vm.engineerJobVisitStatus.ReAssigned
                                    ]
                                    ? _c(
                                        "div",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("v-icon", [_vm._v("date_range")]),
                                          _vm._v("   "),
                                          _c("b", [_vm._v("Started At:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getFormattedDate(
                                                  _vm.engineerVisitDetail
                                                    .visitStartedAt
                                                )
                                              ) +
                                              " "
                                          ),
                                          _vm.showEditButtonsOnJobComplete(
                                            _vm.engineerVisitDetail.status
                                          )
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ma-0 name-edit lighten-2 underline edit-start-time-btn",
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.openOnSiteTimeDialog(
                                                        _vm.engineerVisitDetail
                                                          .visitStartedAt
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("edit")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.engineerVisitDetail.status !==
                                    _vm.engineerJobVisitStatus[
                                      _vm.engineerJobVisitStatus.RunningLate
                                    ] &&
                                  _vm.engineerVisitDetail.status !==
                                    _vm.engineerJobVisitStatus[
                                      _vm.engineerJobVisitStatus
                                        .PolicyHolderAbsent
                                    ] &&
                                  _vm.engineerVisitDetail.status !==
                                    _vm.engineerJobVisitStatus[
                                      _vm.engineerJobVisitStatus.OnTheWay
                                    ]
                                    ? _c(
                                        "div",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("v-flex", [
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Pending
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .Cancelled
                                              ] ||
                                            _vm.engineerVisitDetail.status ===
                                              _vm.engineerJobVisitStatus[
                                                _vm.engineerJobVisitStatus
                                                  .ReAssigned
                                              ]
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("date_range"),
                                                    ]),
                                                    _vm._v("   "),
                                                    _vm.getUserName(
                                                      _vm.engineerVisitDetail
                                                        .processByUserId
                                                    ) &&
                                                    _vm.getUserName(
                                                      _vm.engineerVisitDetail
                                                        .processByUserId
                                                    ) !== "undefined" &&
                                                    _vm.getUserName(
                                                      _vm.engineerVisitDetail
                                                        .processByUserId
                                                    ) !== "undefined undefined"
                                                      ? _c("b", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getEngineerVisitStatus(
                                                                  _vm
                                                                    .engineerVisitDetail
                                                                    .status
                                                                )
                                                              ) +
                                                              " by " +
                                                              _vm._s(
                                                                _vm.getUserName(
                                                                  _vm
                                                                    .engineerVisitDetail
                                                                    .processByUserId
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ])
                                                      : _c("b", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getEngineerVisitStatus(
                                                                  _vm
                                                                    .engineerVisitDetail
                                                                    .status
                                                                )
                                                              ) +
                                                              " by Engineer "
                                                          ),
                                                        ]),
                                                  ],
                                                  1
                                                )
                                              : _vm.engineerVisitDetail
                                                  .status ===
                                                _vm.engineerJobVisitStatus[
                                                  _vm.engineerJobVisitStatus
                                                    .Completed
                                                ]
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("date_range"),
                                                    ]),
                                                    _vm._v("   "),
                                                    _c("b", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getEngineerVisitStatus(
                                                              _vm
                                                                .engineerVisitDetail
                                                                .status
                                                            )
                                                          ) +
                                                          " At: "
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getFormattedDate(
                                                            _vm
                                                              .engineerVisitDetail
                                                              .visitCompletedAt
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _vm.showEditButtonsOnJobComplete(
                                                      _vm.engineerVisitDetail
                                                        .status
                                                    )
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "ma-0 name-edit lighten-2 underline edit-complete-time-btn",
                                                            attrs: { icon: "" },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openOffSiteTimeDialog(
                                                                  _vm
                                                                    .engineerVisitDetail
                                                                    .visitCompletedAt
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [_vm._v("edit")]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  [
                                                    _vm.engineerVisitDetail
                                                      .engineerOffSiteAt
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass: "mb-2",
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "date_range"
                                                              ),
                                                            ]),
                                                            _vm._v("   "),
                                                            _c("b", [
                                                              _vm._v(
                                                                "Off Site At:"
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getFormattedDate(
                                                                    _vm
                                                                      .engineerVisitDetail
                                                                      .engineerOffSiteAt
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c("v-icon", [
                                                      _vm._v("date_range"),
                                                    ]),
                                                    _vm._v("   "),
                                                    _c("b", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getEngineerVisitStatus(
                                                              _vm
                                                                .engineerVisitDetail
                                                                .status
                                                            )
                                                          ) +
                                                          " At: "
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getFormattedDate(
                                                            _vm
                                                              .engineerVisitDetail
                                                              .processAt
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ]),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.engineerVisitDetail.abandonedAt &&
                                  _vm.engineerVisitDetail.abandonNotes
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { "my-2": "" } },
                                            [
                                              _c("label", [
                                                _vm._v("   "),
                                                _c("b", [
                                                  _vm._v("Abandoned Reason:"),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.engineerVisitDetail
                                                        .description
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-flex",
                                            { staticClass: "abandonNotes" },
                                            [
                                              _c("label", [
                                                _vm._v("   "),
                                                _c("b", [
                                                  _vm._v("Abandoned Notes:"),
                                                ]),
                                                _c("pre", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.engineerVisitDetail
                                                        .abandonNotes
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "v-flex",
                                [
                                  _vm.healthAndSafetySignatureUrl !== ""
                                    ? _c(
                                        "v-flex",
                                        { attrs: { lg7: "", "pl-3": "" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-xs-center" },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  "Signed as Complete by Customer"
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "signature-img",
                                                },
                                                [
                                                  _vm.healthAndSafetySignatureUrl !==
                                                  ""
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: _vm.healthAndSafetySignatureUrl,
                                                        },
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src: "/img/signature.png",
                                                          alt: "signature",
                                                        },
                                                      }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.healthAndSafetySignatureUrl
                                    ? _c(
                                        "v-flex",
                                        { attrs: { lg7: "", "pl-3": "" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-xs-center" },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  "Customer not present to sign"
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.disclaimerSignatureUrl !== ""
                                    ? _c(
                                        "v-flex",
                                        { attrs: { lg7: "", "pl-3": "" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-xs-center" },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  "Job Disclaimer Complete by Customer"
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "signature-img",
                                                },
                                                [
                                                  _vm.disclaimerSignatureUrl !==
                                                  ""
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: _vm.disclaimerSignatureUrl,
                                                        },
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src: "/img/signature.png",
                                                          alt: "signature",
                                                        },
                                                      }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.disclaimerSignatureUrl
                                    ? _c(
                                        "v-flex",
                                        { attrs: { lg7: "", "pl-3": "" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-xs-center" },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  "No Disclaimer Signature Present"
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.emergency.followOnWorkRequired &&
                          _vm.emergency.followOnDescription !== ""
                            ? _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "", "px-2": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-divider", {
                                        staticClass: "mb-3 mt-2",
                                      }),
                                      _c("label", { attrs: { for: "" } }, [
                                        _c("b", [
                                          _vm._v("Follow on Work Description"),
                                        ]),
                                      ]),
                                      _c("p", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.emergency.followOnDescription
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          (!_vm.emergency.followOnDescription ||
                            !_vm.engineerJobVisitStatus[
                              _vm.engineerJobVisitStatus.Completed
                            ]) &&
                          _vm.engineerVisitDetail.status ===
                            _vm.engineerJobVisitStatus[
                              _vm.engineerJobVisitStatus.JobStarted
                            ]
                            ? _c("v-divider", { staticClass: "mt-2 mb-3" })
                            : _vm._e(),
                          _c(
                            "v-flex",
                            {
                              staticClass: "text-xs-right",
                              attrs: { xs12: "" },
                            },
                            [
                              _vm.jobType === "SI" &&
                              _vm.engineerVisitDetail.status ===
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.Completed
                                ]
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.siRevisitDialog = true
                                        },
                                      },
                                    },
                                    [_vm._v(" Revisit ")]
                                  )
                                : _vm._e(),
                              _vm.engineerVisitDetail.status ===
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.JobStarted
                                ] ||
                              _vm.engineerVisitDetail.status ===
                                _vm.engineerJobVisitStatus[
                                  _vm.engineerJobVisitStatus.PolicyHolderAbsent
                                ]
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.completeVisit },
                                    },
                                    [_vm._v(" Visit Complete ")]
                                  )
                                : _vm._e(),
                              _vm.engineerVisitDetail.status ===
                              _vm.engineerJobVisitStatus[
                                _vm.engineerJobVisitStatus.JobStarted
                              ]
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "red white--text",
                                      on: {
                                        click: _vm.openvisitAbandonedDialog,
                                      },
                                    },
                                    [_vm._v(" Abandon Visit ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showLiveLocation
                ? _c(
                    "v-dialog",
                    {
                      attrs: { fullscreen: true, persistent: "" },
                      model: {
                        value: _vm.showLiveLocation,
                        callback: function ($$v) {
                          _vm.showLiveLocation = $$v
                        },
                        expression: "showLiveLocation",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { card: "", dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [_vm._v("Live Location")]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showLiveLocation = false
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c("v-layout", [
                            _c(
                              "div",
                              { staticClass: "map" },
                              [
                                _c("Map", {
                                  staticStyle: { height: "1000px" },
                                  attrs: {
                                    "map-id": new Date().getTime().toString(),
                                    "source-latitude": _vm.latitude,
                                    "source-longitude": _vm.longitude,
                                    "destination-address":
                                      _vm.engineerVisitDetail.address,
                                    "zoom-level": 15,
                                    "previous-latitude": _vm.previousLatitude
                                      ? _vm.previousLatitude
                                      : _vm.latitude,
                                    "previous-longitude": _vm.previousLongitude
                                      ? _vm.previousLongitude
                                      : _vm.longitude,
                                    "heading-magnetic-north":
                                      _vm.headingMagneticNorth,
                                    "show-live-location": true,
                                    "show-fullscreen-control": false,
                                    "engineer-job-start-latitude": _vm
                                      .engineerVisitDetail.jobStartLatitude
                                      ? _vm.engineerVisitDetail.jobStartLatitude
                                      : null,
                                    "engineer-job-start-longitude": _vm
                                      .engineerVisitDetail.jobStartLongitude
                                      ? _vm.engineerVisitDetail
                                          .jobStartLongitude
                                      : null,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.additionalRequestDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "650",
                        persistent: "",
                        "content-class": "v-dialog--scrollable",
                      },
                      model: {
                        value: _vm.additionalRequestDialog,
                        callback: function ($$v) {
                          _vm.additionalRequestDialog = $$v
                        },
                        expression: "additionalRequestDialog",
                      },
                    },
                    [
                      _c("AdditionalRequest", {
                        attrs: { "job-id": _vm.jobId, "item-id": _vm.itemId },
                        on: {
                          closeAdditionalRequestDialog:
                            _vm.closeAdditionalRequestDialog,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.visitCompletedDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "450",
                        persistent: "",
                        "content-class": "v-dialog--scrollable",
                      },
                      model: {
                        value: _vm.visitCompletedDialog,
                        callback: function ($$v) {
                          _vm.visitCompletedDialog = $$v
                        },
                        expression: "visitCompletedDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { card: "", dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("Visit Complete Time"),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.closeVisitCompletedDialog },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            { staticClass: "scroll-content-dialog px-4 pt-4" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pr-2": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "dateTimePickerEmgComplete",
                                        attrs: {
                                          "date-time": _vm.visitCompletedAt,
                                          "is-static-location": false,
                                          "place-holder-text":
                                            "Select visiting complete date",
                                          "is-current-time": true,
                                          "is-validation-required": true,
                                          "show-present-time-icon": true,
                                          "min-date":
                                            _vm.emergencyCreatedAtDate,
                                          "min-time": _vm.dateMinTime,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            _vm.visitCompletedAt = $event
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            _vm.visitCompletedAt = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          label: "Site Note",
                                          rows: "5",
                                          name: "siteNote",
                                        },
                                        model: {
                                          value: _vm.siteNote,
                                          callback: function ($$v) {
                                            _vm.siteNote =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression: "siteNote",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            { staticClass: "px-4" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", flat: "flat" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.closeVisitCompletedDialog.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-0",
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.onSubmitLoading,
                                    disabled: _vm.onSubmitLoading,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.visitCompleted.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Submit ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.follwOnWorkDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "650",
                        persistent: "",
                        "content-class": "v-dialog--scrollable",
                      },
                      model: {
                        value: _vm.follwOnWorkDialog,
                        callback: function ($$v) {
                          _vm.follwOnWorkDialog = $$v
                        },
                        expression: "follwOnWorkDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { card: "", dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [_vm._v("Follow On Work")]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.follwOnWorkDialog = false
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            { staticClass: "px-3 scroll-content-dialog" },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-textarea", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "required",
                                        attrs: {
                                          label: "Description",
                                          rows: "5",
                                          required: "",
                                          "data-vv-scope": "formReference",
                                          "data-vv-name": "Description",
                                          "error-messages":
                                            _vm.errors.collect("Description"),
                                        },
                                        model: {
                                          value:
                                            _vm.follwOnWorkModel
                                              .followOnDescription,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.follwOnWorkModel,
                                              "followOnDescription",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "follwOnWorkModel.followOnDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            { staticClass: "px-3" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", flat: "flat" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.follwOnWorkDialog = false
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-0",
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.onSubmitLoading,
                                    loading: _vm.onSubmitLoading,
                                  },
                                  on: { click: _vm.submitFollowOnWorkDetail },
                                },
                                [_vm._v(" Submit ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "700",
                    persistent: "",
                    "content-class": "v-dialog--scrollable",
                  },
                  model: {
                    value: _vm.configureDropdownDialog,
                    callback: function ($$v) {
                      _vm.configureDropdownDialog = $$v
                    },
                    expression: "configureDropdownDialog",
                  },
                },
                [
                  _c("ConfigureDropdown", {
                    attrs: { "record-type": _vm.configureDropdownType },
                    on: {
                      CloseConfigureDropdownDialog:
                        _vm.onConfigureDropdownDialogClose,
                    },
                  }),
                ],
                1
              ),
              _vm.addVisitStartTimeDialog && _vm.emergency
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "450",
                        persistent: "",
                        "content-class": "v-dialog--scrollable",
                      },
                      model: {
                        value: _vm.addVisitStartTimeDialog,
                        callback: function ($$v) {
                          _vm.addVisitStartTimeDialog = $$v
                        },
                        expression: "addVisitStartTimeDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { card: "", dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("Visit Start Time"),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.closeVisitStartedDialog },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            { staticClass: "scroll-content-dialog px-4 pt-2" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pt-2": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "firstAssignedAtUtc",
                                        attrs: {
                                          "date-time": _vm.firstAssignedAtUtc,
                                          "is-static-location": false,
                                          "place-holder-text":
                                            "Select Start date",
                                          "is-current-time": true,
                                          "is-validation-required": true,
                                          "show-present-time-icon": _vm
                                            .engineerVisitDetail
                                            .visitCompletedAt
                                            ? false
                                            : true,
                                          "min-date":
                                            _vm.emergencyCreatedAtDate,
                                          "max-date":
                                            _vm.emergencyCompletedAtDate,
                                          "min-time": _vm.dateMinTime,
                                          "max-time": _vm.dateMaxTime,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            _vm.firstAssignedAtUtc = $event
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            _vm.firstAssignedAtUtc = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            { staticClass: "px-4" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", flat: "flat" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.closeVisitStartedDialog.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-0",
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.addVisitStartTimeLoading,
                                    loading: _vm.addVisitStartTimeLoading,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.addVisitStartTime.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Submit ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showVisitHealthAndSafetyReportDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "1000",
                        persistent: "",
                        "content-class": "v-dialog--scrollable",
                      },
                      model: {
                        value: _vm.showVisitHealthAndSafetyReportDialog,
                        callback: function ($$v) {
                          _vm.showVisitHealthAndSafetyReportDialog = $$v
                        },
                        expression: "showVisitHealthAndSafetyReportDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { card: "", dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("Visit Health And Safety Details"),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showVisitHealthAndSafetyReportDialog = false
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            { staticClass: "px-3 scroll-content-dialog" },
                            [
                              _c("HealthSafetyQAReport", {
                                attrs: {
                                  "get-health-safety-q-a":
                                    _vm.getVisitHealthAndSafetyDetails,
                                  "job-id": _vm.jobId,
                                  emergency: _vm.emergency,
                                  "engineer-visit-detail":
                                    _vm.engineerVisitDetail,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            { staticClass: "px-3" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", flat: "flat" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showVisitHealthAndSafetyReportDialog = false
                                    },
                                  },
                                },
                                [_vm._v(" Close ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showGasSafetyCheckDetails
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "700",
                        persistent: "",
                        "content-class": "v-dialog--scrollable",
                      },
                      model: {
                        value: _vm.showGasSafetyCheckDetails,
                        callback: function ($$v) {
                          _vm.showGasSafetyCheckDetails = $$v
                        },
                        expression: "showGasSafetyCheckDetails",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { card: "", dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("Gas Safety Check Report"),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showGasSafetyCheckDetails = false
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            { staticClass: "px-3 scroll-content-dialog" },
                            [
                              _c("GasSafetyQAReport", {
                                ref: "refGasSafetyQAReport",
                                attrs: {
                                  "job-id": _vm.jobId,
                                  "item-id": _vm.itemId,
                                  "emergency-id": _vm.emergencyId,
                                  "engineer-name": _vm.engineerName,
                                  "from-contractor-details-view": false,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            { staticClass: "px-3" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-0",
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.generatePDFInProcess,
                                    loading: _vm.generatePDFInProcess,
                                  },
                                  on: { click: _vm.generateGasReport },
                                },
                                [_vm._v(" Generate PDF ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.visitAbandonedDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "650",
                        persistent: "",
                        "content-class": "v-dialog--scrollable",
                      },
                      model: {
                        value: _vm.visitAbandonedDialog,
                        callback: function ($$v) {
                          _vm.visitAbandonedDialog = $$v
                        },
                        expression: "visitAbandonedDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { card: "", dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("Visit Abandoned"),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.visitAbandonedDialog = false
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            { staticClass: "scroll-content-dialog px-4 pt-4" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "required",
                                        attrs: {
                                          items: _vm.abandonJobReasonList,
                                          "item-text": "description",
                                          "item-value": "description",
                                          label: "Reason",
                                          required: "",
                                          "data-vv-scope":
                                            _vm.visitAbandonedScope,
                                          "data-vv-name": "Reason",
                                          "error-messages":
                                            _vm.errors.collect("Reason"),
                                        },
                                        on: {
                                          change: _vm.onAbandonJobReasonChange,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "v-list-tile",
                                                    [
                                                      _c(
                                                        "v-list-tile-content",
                                                        [
                                                          _c(
                                                            "v-list-tile-title",
                                                            {
                                                              class:
                                                                data.item
                                                                  .description ===
                                                                "Configure"
                                                                  ? "bold-select"
                                                                  : "",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .description
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3659597833
                                        ),
                                        model: {
                                          value: _vm.abandonReason,
                                          callback: function ($$v) {
                                            _vm.abandonReason = $$v
                                          },
                                          expression: "abandonReason",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pr-2": "" } },
                                    [
                                      _c("v-textarea", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "required",
                                        attrs: {
                                          label: "Note",
                                          required: "",
                                          "data-vv-scope":
                                            _vm.visitAbandonedScope,
                                          "data-vv-name": "Note",
                                          "error-messages":
                                            _vm.errors.collect("Note"),
                                        },
                                        model: {
                                          value: _vm.abandonNotes,
                                          callback: function ($$v) {
                                            _vm.abandonNotes =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression: "abandonNotes",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            { staticClass: "px-4" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", flat: "flat" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.visitAbandonedDialog = false
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-0",
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.onSubmitLoading,
                                    disabled: _vm.onSubmitLoading,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.visitAbandoned.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Submit ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.siRevisitDialog
                ? _c("SIRevisit", {
                    ref: "siRevisitRef",
                    attrs: {
                      "job-id": _vm.jobId,
                      "engineer-visit-detail": _vm.engineerVisitDetail,
                      "return-visit-type-list": _vm.returnVisitTypeList,
                    },
                    on: {
                      openConfigureDropDown: _vm.openConfigureDropdown,
                      closeReturnVisitDialog: function ($event) {
                        _vm.siRevisitDialog = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("ContractorRatePackageList", {
        attrs: { readonly: true, "contractor-id": _vm.contractor?.id },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }