var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: _vm.contractorshow
        ? "mid-section-content contractor-section py-3"
        : "contractor-section py-3",
    },
    [
      _c("v-progress-circular", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.getContractor,
            expression: "!getContractor",
          },
        ],
        style: _vm.getLoaderStyle(70),
        attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
      }),
      _vm.getContractor
        ? _c(
            "div",
            {
              class:
                (_vm.contractordropdown && !_vm.isUserRoleContractor) ||
                (_vm.isUserRoleContractor &&
                  (_vm.hasAnySubcontractor || _vm.showSelectVisitTypeDropdown))
                  ? "showcontractor"
                  : "",
            },
            [
              _vm.contractordropdown
                ? _c(
                    "div",
                    {
                      staticClass: "contractor-mgm-search",
                      class:
                        _vm.isUserRoleContractor &&
                        _vm.showSelectVisitTypeDropdown &&
                        !_vm.hasAnySubcontractor
                          ? "contractor-search-cn"
                          : "",
                      attrs: { wrap: "" },
                    },
                    [
                      _vm.showSelectVisitTypeDropdown
                        ? _c("v-autocomplete", {
                            staticClass: "visit-type mr-3",
                            class:
                              _vm.isUserRoleContractor &&
                              _vm.hasAnySubcontractor
                                ? "visit-type-space"
                                : "",
                            attrs: {
                              items: _vm.getVisitTypes,
                              "item-text": "alterText",
                              "item-value": "description",
                              label: "Visit Type",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.filterJobsBySelectedVisitType($event)
                              },
                            },
                            model: {
                              value: _vm.selectedVisitType,
                              callback: function ($$v) {
                                _vm.selectedVisitType = $$v
                              },
                              expression: "selectedVisitType",
                            },
                          })
                        : _vm._e(),
                      !_vm.isUserRoleContractor
                        ? [
                            _c("v-select", {
                              staticClass: "contractor_status",
                              attrs: {
                                items: _vm.getPreferredContractorOptions,
                                label: "Contractor Status",
                                "item-text": "description",
                                "item-value": "id",
                                disabled: _vm.isVisitSummaryDataLoading,
                              },
                              model: {
                                value: _vm.selectedPreferredContractor,
                                callback: function ($$v) {
                                  _vm.selectedPreferredContractor =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "selectedPreferredContractor",
                              },
                            }),
                            _c("v-autocomplete", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "required ml-3 contractor_list",
                              attrs: {
                                label: "Search contractor",
                                required: "",
                                loading:
                                  _vm.getContractorDetailLoading ||
                                  _vm.getContractorJobsLoading ||
                                  _vm.getContractorEngineersLoading,
                                items: _vm.contractorList,
                                "item-text": "companyName",
                                "item-value": "id",
                                "data-vv-scope": "frmContractor",
                                "data-vv-name": "Search contractor",
                                "error-messages":
                                  _vm.errors.collect("Search contractor"),
                                disabled: _vm.isVisitSummaryDataLoading,
                              },
                              model: {
                                value: _vm.selectedContractorId,
                                callback: function ($$v) {
                                  _vm.selectedContractorId = $$v
                                },
                                expression: "selectedContractorId",
                              },
                            }),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ma-0 mt-2 add-contractor-btn",
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.isVisitSummaryDataLoading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addContractorClick()
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("person_add"),
                                ]),
                                _vm._v("   Contractor "),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.isUserRoleContractor && _vm.hasAnySubcontractor
                ? _c(
                    "div",
                    {
                      staticClass: "contractor-mgm-search contractor-search-cn",
                      attrs: { wrap: "" },
                    },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required ml-3 contractor_list",
                        attrs: {
                          label: "Search contractor",
                          required: "",
                          loading:
                            _vm.getContractorDetailLoading ||
                            _vm.getContractorJobsLoading ||
                            _vm.getContractorEngineersLoading,
                          items: _vm.subcontractors,
                          "item-text": "companyName",
                          "item-value": "id",
                          "data-vv-scope": "frmContractor",
                          "data-vv-name": "Search contractor",
                          "error-messages":
                            _vm.errors.collect("Search contractor"),
                        },
                        model: {
                          value: _vm.selectedContractorId,
                          callback: function ($$v) {
                            _vm.selectedContractorId = $$v
                          },
                          expression: "selectedContractorId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tabs",
                {
                  attrs: { "icons-and-text": "", "hide-slider": true },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c("v-tabs-slider", { attrs: { color: "yellow" } }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "bar",
                          rawName: "v-bar",
                          value: { useScrollbarPseudo: true },
                          expression: "{ useScrollbarPseudo: true }",
                        },
                      ],
                      staticClass: "tab-wrap",
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _vm.contractor?.preferredContractor &&
                          _vm.contractor.preferredContractor !==
                            "ApplicationPending" &&
                          _vm.contractor.preferredContractor !==
                            "ApplicationInProgress" &&
                          _vm.contractor.preferredContractor !== "Archived"
                            ? _c(
                                "div",
                                { staticClass: "badge-img badges mt-1" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/img/" +
                                        _vm.contractor.preferredContractor +
                                        "-Badge.png",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#tab-1" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.removecontspec(true)
                                },
                              },
                            },
                            [
                              _vm._v(" Jobs "),
                              _c("v-icon", [_vm._v("date_range")]),
                            ],
                            1
                          ),
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#tab-2" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.removecontspec()
                                },
                              },
                            },
                            [
                              _vm._v(" Contractor Profile "),
                              _c(
                                "svg",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    "xmlns:xlink":
                                      "http://www.w3.org/1999/xlink",
                                    version: "1.1",
                                    viewBox: "0 0 297 297",
                                    "enable-background": "new 0 0 297 297",
                                    width: "25px",
                                    height: "25px",
                                  },
                                },
                                [
                                  _c("g", [
                                    _c("path", {
                                      attrs: {
                                        d: "M148.51,117.216c32.317,0,58.608-26.291,58.608-58.608S180.827,0,148.51,0c-32.317,0-58.608,26.291-58.608,58.608   S116.193,117.216,148.51,117.216z",
                                        fill: "#757575",
                                      },
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "m227.154,145.618c-0.025-0.008-0.073-0.026-0.098-0.032-7.631-1.864-30.999-5.133-30.999-5.133-2.638-0.812-5.457,0.585-6.406,3.188l-35.174,96.509c-2.029,5.567-9.903,5.567-11.932,0l-35.174-96.509c-0.766-2.102-2.75-3.42-4.876-3.42-0.504,0-24.531,3.369-32.53,5.358-21.858,5.435-35.645,26.929-35.645,49.329v80.302c0,12.034 9.756,21.79 21.79,21.79h184.782c12.034,0 21.79-9.756 21.79-21.79v-80.569c-0.001-22.303-14.328-42.096-35.528-49.023z",
                                        fill: "#757575",
                                      },
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "m161.775,138.613c-1.404-1.53-3.456-2.299-5.532-2.299h-15.485c-2.076,0-4.129,0.77-5.532,2.299-2.173,2.368-2.489,5.789-0.946,8.462l8.278,12.479-3.875,32.69 7.631,20.3c0.744,2.042 3.631,2.042 4.375,0l7.631-20.3-3.875-32.69 8.278-12.479c1.541-2.673 1.225-6.094-0.948-8.462z",
                                        fill: "#757575",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#tab-9" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.removecontspec()
                                },
                              },
                            },
                            [
                              _vm._v(" Bank Details "),
                              _c("v-icon", [_vm._v("credit_card")]),
                            ],
                            1
                          ),
                          !_vm.isUserRoleContractor
                            ? _c(
                                "v-tab",
                                {
                                  attrs: { href: "#tab-3" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.addcontspec.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Availability "),
                                  _c("v-icon", [_vm._v("access_time")]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#tab-4" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.removecontspec()
                                },
                              },
                            },
                            [
                              _vm._v(" Engineers "),
                              _c(
                                "svg",
                                {
                                  staticStyle: {
                                    "enable-background":
                                      "new 0 0 535.806 535.807",
                                  },
                                  attrs: {
                                    id: "Capa_1",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    "xmlns:xlink":
                                      "http://www.w3.org/1999/xlink",
                                    version: "1.1",
                                    x: "0px",
                                    y: "0px",
                                    width: "25px",
                                    height: "25px",
                                    viewBox: "0 0 535.806 535.807",
                                    "xml:space": "preserve",
                                  },
                                },
                                [
                                  _c("g", [
                                    _c("path", {
                                      attrs: {
                                        d: "M440.956,373.932c-11.934-13.158-26.315-19.584-44.676-19.584h-38.686l-25.398-24.479   c-18.666,15.3-41.31,24.174-65.791,24.174c-22.95,0-44.676-7.956-62.424-21.726l-22.645,21.726h-40.262   c-20.502,0-36.414,7.038-48.96,21.421c-36.414,42.227-30.294,132.498-27.54,160.344h407.592   C474.31,507.654,477.982,415.242,440.956,373.932z",
                                        fill: "#757575",
                                      },
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "M160.343,182.376c-7.344,6.12-12.24,15.912-12.24,27.234c0,16.83,11.016,30.6,25.092,33.048   c3.06,25.398,13.464,47.736,29.07,64.26c3.365,3.366,6.731,6.732,10.403,9.486c4.591,3.672,9.486,6.732,14.688,9.486   c11.628,6.119,24.479,9.485,38.25,9.485c13.77,0,26.623-3.366,38.25-9.485c5.202-2.754,10.098-5.814,14.688-9.486   c3.673-2.754,7.038-6.12,10.404-9.486c15.3-16.523,26.01-38.556,28.764-63.954c0.307,0,0.612,0,0.918,0   c16.219,0,29.07-14.994,29.07-33.354c0-11.322-4.896-21.114-12.24-27.234H160.343L160.343,182.376z",
                                        fill: "#757575",
                                      },
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "M377.409,118.116c-9.486-31.518-34.578-63.648-66.402-80.172v71.91v9.792c0,0.612,0,0.918,0,1.224   c-0.306,3.366-0.918,6.426-2.447,9.486c-3.979,7.65-11.935,13.158-21.114,13.158h-4.896h-33.66c-8.568,0-16.219-4.59-20.196-11.322   c-1.836-2.754-2.754-5.813-3.366-9.18c-0.306-1.224-0.306-2.142-0.306-3.366v-8.568v-73.44   c-31.824,16.83-56.916,48.96-66.402,80.478l-2.142,6.732h-17.442v38.25h19.278h26.928h11.322h147.493h11.016h41.7v-1.836v-36.414   h-17.22L377.409,118.116z",
                                        fill: "#757575",
                                      },
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "M248.777,134.028h38.25c5.508,0,10.098-3.06,12.546-7.65c1.224-2.142,1.836-4.284,1.836-6.732v-2.754V105.57V33.354V22.95   v-3.978c0-2.448-0.612-4.59-1.224-6.732C297.432,5.202,290.394,0,282.438,0h-33.661c-7.344,0-13.464,5.508-14.076,12.546   c0,0.612-0.306,1.224-0.306,1.836v8.568v10.404v73.44v11.628v1.224c0,3.06,0.918,5.814,2.448,8.262   C239.598,131.58,243.881,134.028,248.777,134.028z",
                                        fill: "#757575",
                                      },
                                    }),
                                  ]),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                ]
                              ),
                            ]
                          ),
                          !_vm.isUserRoleContractor
                            ? _c(
                                "v-tab",
                                {
                                  attrs: { href: "#tab-5" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.removecontspec()
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Coverage "),
                                  _c("v-icon", [_vm._v("place")]),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isUserRoleContractor
                            ? _c(
                                "v-tab",
                                {
                                  attrs: { href: "#tab-6" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.removecontspec()
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Rate "),
                                  _c("img", {
                                    staticClass: "menu-icon menu-images",
                                    attrs: {
                                      src: "/img/pound-symbol-grey.svg",
                                      alt: "",
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "active-menu-icon menu-images",
                                    attrs: {
                                      src: "/img/pound-symbol-pink.svg",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          !_vm.isUserRoleContractor
                            ? _c(
                                "v-tab",
                                {
                                  attrs: { href: "#tab-7" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.disablecontractor.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Rate Template "),
                                  _c("img", {
                                    staticClass: "menu-icon menu-images",
                                    attrs: {
                                      src: "/img/pound-symbol-grey.svg",
                                      alt: "",
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "active-menu-icon menu-images",
                                    attrs: {
                                      src: "/img/pound-symbol-pink.svg",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#tab-8" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.removecontspec()
                                },
                              },
                            },
                            [
                              _vm._v(" Financial Summary "),
                              _c("img", {
                                staticClass: "menu-icon menu-images",
                                attrs: {
                                  src: "/img/finance-grey.svg",
                                  alt: "",
                                },
                              }),
                              _c("img", {
                                staticClass: "active-menu-icon menu-images",
                                attrs: {
                                  src: "/img/finance-pink.svg",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#tab-10" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.removecontspec()
                                },
                              },
                            },
                            [
                              _vm._v(" Trade Association Memberships "),
                              _c("v-icon", [_vm._v("people")]),
                            ],
                            1
                          ),
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#tab-11" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.removecontspec()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " Health, Safety, Wellbeing and Environment "
                              ),
                              _c(
                                "svg",
                                {
                                  staticStyle: {
                                    "enable-background": "new 0 0 455 455",
                                  },
                                  attrs: {
                                    id: "Layer_1",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    "xmlns:xlink":
                                      "http://www.w3.org/1999/xlink",
                                    version: "1.1",
                                    x: "0px",
                                    y: "0px",
                                    viewBox: "0 0 455 455",
                                    "xml:space": "preserve",
                                    fill: "#949494",
                                    width: "25px",
                                    height: "25px",
                                  },
                                },
                                [
                                  _c("g", [
                                    _c("polygon", {
                                      attrs: {
                                        points:
                                          "370.5,158.498 310.5,158.498 310.5,227.498 241.5,227.498 241.5,287.498 310.5,287.498 310.5,356.498    370.5,356.498 370.5,287.498 439.5,287.498 439.5,227.498 370.5,227.498  ",
                                      },
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "M211.5,197.498h69v-69h120v69h34.799c12.468-20,19.701-42.674,19.701-67.53C455,60.686,398.847,4.51,329.579,4.51   c-42.087,0-79.329,20.731-102.079,52.544C204.75,25.24,167.508,4.501,125.421,4.501C56.153,4.501,0,60.664,0,129.947   c0,30.118,10.612,57.752,28.299,79.376L227.5,450.499l53-64.169v-68.832h-69V197.498z",
                                      },
                                    }),
                                  ]),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                  _c("g"),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#tab-12" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.removecontspec()
                                },
                              },
                            },
                            [
                              _vm._v(" Signature "),
                              _c("v-icon", [_vm._v("edit")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "tab-1" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "grey lighten-3 elevation-1 card-box-shadow-none",
                          attrs: { flat: "" },
                        },
                        [
                          _vm.getContractor
                            ? _c("ContractorJobs", {
                                attrs: {
                                  id: _vm.getContractorId,
                                  "contractor-jobs": _vm.getJobContractors,
                                  engineers: _vm.getEngineers,
                                  "selected-job-to-open": _vm.selectedJobToOpen,
                                  "visit-type": _vm.selectedVisitType,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.activeTab === "tab-2"
                    ? _c(
                        "v-tab-item",
                        { attrs: { value: "tab-2" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-3",
                              attrs: { flat: "" },
                            },
                            [
                              _c("ContractorProfile", {
                                staticClass: "contractor-profile-content",
                                attrs: {
                                  "contractor-detail": Object.assign(
                                    {},
                                    _vm.getContractor
                                  ),
                                  "total-engineer": _vm.getTotalEngineersLength,
                                  engineer: _vm.getFirstEngineerDetails,
                                  "contractor-list": _vm.contractorList,
                                },
                                on: {
                                  "update:engineer": function ($event) {
                                    _vm.getFirstEngineerDetails = $event
                                  },
                                  isIndependentContractor:
                                    _vm.isIndependentContractor,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeTab === "tab-9"
                    ? _c(
                        "v-tab-item",
                        { attrs: { value: "tab-9" } },
                        [
                          _c("v-card", { attrs: { flat: "" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "elevation-1 card-box-shadow-none",
                                    attrs: { flat: "" },
                                  },
                                  [
                                    _c("v-card-title", [
                                      _c("h3", [_vm._v("Bank Details")]),
                                    ]),
                                    _c("v-divider"),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c("ContractorBankDetails", {
                                          ref: "contractorBankDetails",
                                          attrs: {
                                            "contractor-bank-detail":
                                              _vm.getContractorBankDetail,
                                            "contractor-id":
                                              _vm.getContractorId,
                                          },
                                          on: {
                                            updateBankDetail:
                                              _vm.onContractorBankDetailUpdate,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isUserRoleContractor && _vm.activeTab === "tab-3"
                    ? _c(
                        "v-tab-item",
                        { attrs: { value: "tab-3" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "pa-3 contractor-specialization-content pr-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "bar",
                                      rawName: "v-bar",
                                      value: { useScrollbarPseudo: true },
                                      expression:
                                        "{ useScrollbarPseudo: true }",
                                    },
                                  ],
                                  staticClass: "fill-height pr-3",
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _vm.getContractor
                                        ? _c("ContractorSpecialization", {
                                            attrs: {
                                              "contractor-id":
                                                _vm.getContractor.id,
                                              contractor: _vm.getContractor,
                                            },
                                            on: {
                                              updateContractorAvailability:
                                                _vm.onContractorAvailabilityUpdate,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeTab === "tab-4"
                    ? _c(
                        "v-tab-item",
                        { attrs: { value: "tab-4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-3",
                              attrs: { flat: "" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "elevation-1" },
                                [
                                  _vm.getContractor
                                    ? _c("EngineerManagement", {
                                        attrs: {
                                          "contractor-id": _vm.getContractorId,
                                          "is-independent": _vm.isIndependent,
                                          "engineer-availability": _vm
                                            .getContractor
                                            .contractorAvailability
                                            ? _vm.getContractor
                                                .contractorAvailability
                                            : [],
                                          engineers: _vm.getEngineers,
                                        },
                                        on: {
                                          "update:engineers": function (
                                            $event
                                          ) {
                                            _vm.getEngineers = $event
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isUserRoleContractor && _vm.activeTab === "tab-5"
                    ? _c(
                        "v-tab-item",
                        { attrs: { value: "tab-5" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-3",
                              attrs: { flat: "" },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _vm.getContractor
                                    ? _c("ContractorArea", {
                                        attrs: {
                                          "contractor-id": _vm.getContractor.id,
                                          coverage: _vm.getContractorCoverage,
                                          "contractor-availability":
                                            _vm.getContractorAvailability,
                                        },
                                        on: {
                                          coverageChange: _vm.onCoverageChange,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isUserRoleContractor
                    ? _c(
                        "v-tab-item",
                        { staticClass: "px-3", attrs: { value: "tab-6" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-3",
                              attrs: { flat: "" },
                            },
                            [
                              _c("ContractorRate", {
                                attrs: {
                                  readonly: false,
                                  "all-contractor-rate-package-list":
                                    _vm.allContractorRatePackageList,
                                  "contractor-id": _vm.getContractor.id,
                                  "contractor-name":
                                    _vm.getContractor.companyName,
                                  "rate-package-id":
                                    _vm.getContractor.ratePackageId,
                                },
                                on: {
                                  "update:allContractorRatePackageList":
                                    function ($event) {
                                      _vm.allContractorRatePackageList = $event
                                    },
                                  "update:all-contractor-rate-package-list":
                                    function ($event) {
                                      _vm.allContractorRatePackageList = $event
                                    },
                                  "update:ratePackageId": function ($event) {
                                    return _vm.$set(
                                      _vm.getContractor,
                                      "ratePackageId",
                                      $event
                                    )
                                  },
                                  "update:rate-package-id": function ($event) {
                                    return _vm.$set(
                                      _vm.getContractor,
                                      "ratePackageId",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isUserRoleContractor
                    ? _c(
                        "v-tab-item",
                        { staticClass: "px-3", attrs: { value: "tab-7" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-3",
                              attrs: { flat: "" },
                            },
                            [
                              _c("ContractorRate", {
                                attrs: {
                                  readonly: false,
                                  "all-contractor-rate-package-list":
                                    _vm.allContractorRatePackageList,
                                },
                                on: {
                                  "update:allContractorRatePackageList":
                                    function ($event) {
                                      _vm.allContractorRatePackageList = $event
                                    },
                                  "update:all-contractor-rate-package-list":
                                    function ($event) {
                                      _vm.allContractorRatePackageList = $event
                                    },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeTab === "tab-8"
                    ? _c(
                        "v-tab-item",
                        { attrs: { value: "tab-8" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-3",
                              attrs: { flat: "" },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _vm.getContractor
                                    ? _c("ContractorFinancialSummary", {
                                        attrs: {
                                          "contractor-id":
                                            _vm.selectedContractorId,
                                          "is-visit-summary-data-loading":
                                            _vm.isVisitSummaryDataLoading,
                                          engineers: _vm.engineers,
                                        },
                                        on: {
                                          "update:isVisitSummaryDataLoading":
                                            function ($event) {
                                              _vm.isVisitSummaryDataLoading =
                                                $event
                                            },
                                          "update:is-visit-summary-data-loading":
                                            function ($event) {
                                              _vm.isVisitSummaryDataLoading =
                                                $event
                                            },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeTab === "tab-10"
                    ? _c(
                        "v-tab-item",
                        { attrs: { value: "tab-10" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _c("h3", [
                                  _vm._v("Trade Association Memberships"),
                                ]),
                              ]),
                              _c("v-divider"),
                              _c(
                                "v-card-text",
                                [
                                  _vm.getContractor
                                    ? _c(
                                        "ContractorTradeAssociationMemberships",
                                        {
                                          attrs: {
                                            "contractor-id":
                                              _vm.getContractor.id,
                                            contractor: _vm.getContractor,
                                            "document-detail-list":
                                              _vm.documentDetailList,
                                          },
                                          on: {
                                            onSaveTradeMembershipDetails:
                                              _vm.onSaveTradeMembershipDetails,
                                          },
                                        }
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeTab === "tab-11"
                    ? _c(
                        "v-tab-item",
                        { attrs: { value: "tab-11" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _c("h3", [
                                  _vm._v(
                                    "Health, Safety, Wellbeing and Environment"
                                  ),
                                ]),
                              ]),
                              _c("v-divider"),
                              _c(
                                "v-card-text",
                                [
                                  _vm.getContractor
                                    ? _c(
                                        "ContractorHSAndWellbeingEnvironment",
                                        {
                                          attrs: {
                                            "health-safety-wellbeing-environment":
                                              _vm.getContractor
                                                .healthSafetyWellbeingEnvironment,
                                            "contractor-id":
                                              _vm.getContractor.id,
                                            "document-detail-list":
                                              _vm.documentDetailList,
                                          },
                                          on: {
                                            onSaveHealthSafetyWellbeingEnvironment:
                                              _vm.onSaveHealthSafetyWellbeingEnvironment,
                                          },
                                        }
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeTab === "tab-12"
                    ? _c(
                        "v-tab-item",
                        { attrs: { value: "tab-12" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _c("h3", [_vm._v("Declaration")]),
                              ]),
                              _c("v-divider"),
                              _c(
                                "v-card-text",
                                [
                                  _vm.getContractor
                                    ? _c("ContractorSignature", {
                                        attrs: {
                                          "contractor-id": _vm.getContractor.id,
                                          "contractor-signature":
                                            _vm.getContractor.signature,
                                        },
                                        on: {
                                          onSaveContractorSignatureDetails:
                                            _vm.onSaveContractorSignatureDetails,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showContractorProfileDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showContractorProfileDialog,
                callback: function ($$v) {
                  _vm.showContractorProfileDialog = $$v
                },
                expression: "showContractorProfileDialog",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "bar",
                      rawName: "v-bar",
                      value: { useScrollbarPseudo: true },
                      expression: "{ useScrollbarPseudo: true }",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    [
                      _c("ContractorProfile", {
                        attrs: {
                          "contractor-detail": _vm.contractorProfileData,
                          "show-contractor-profile-in-dialog": true,
                        },
                        on: {
                          saveContractor: _vm.saveContractor,
                          onCancelChanges: function ($event) {
                            _vm.showContractorProfileDialog = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.jobId && _vm.contractorAppointedId
        ? _c("ContractorJobOfferModal", {
            attrs: {
              "job-id": _vm.jobId,
              "appointment-id": _vm.contractorAppointedId,
            },
            on: { closeJobOfferDialog: _vm.closeJobOfferDialog },
          })
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }