var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c("v-text-field", {
        class: { "font-weight-bold": _vm.detail.isSet },
        attrs: {
          label: _vm.label,
          readonly: true,
          loading: _vm.detail.awaiting,
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                _c(
                  "v-flex",
                  [
                    _c("AgentAssistRetrievalButton", {
                      attrs: {
                        "is-set": _vm.detail.isSet,
                        disabled: _vm.disabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("retrieve")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.detail.value,
          callback: function ($$v) {
            _vm.$set(_vm.detail, "value", $$v)
          },
          expression: "detail.value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }