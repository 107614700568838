var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "policy-selector-content" },
    [
      _c("treeselect", {
        staticClass: "tree-select",
        attrs: {
          "disable-branch-nodes": true,
          "show-count": true,
          placeholder: "Select Policy...",
          "aspect-ratio": "1",
          options: _vm.convertPoliciesIntoTree,
          "pre-selected-policy": _vm.preSelectedPolicy,
        },
        on: { input: _vm.policySelected },
        scopedSlots: _vm._u([
          {
            key: "option-label",
            fn: function ({ node, count, labelClassName }) {
              return _c(
                "label",
                { class: labelClassName, staticStyle: { height: "48px" } },
                [
                  _c(
                    "div",
                    { staticClass: "one-line" },
                    [
                      _c("pre", { staticClass: "result" }, [
                        _vm._v(_vm._s(node.label)),
                      ]),
                      _vm.isDefaultPolicy(node)
                        ? _c(
                            "v-chip",
                            {
                              staticClass: "white--text",
                              staticStyle: { "font-weight": "small" },
                              attrs: {
                                color: "secondary",
                                small: "",
                                disabled: "",
                              },
                            },
                            [_vm._v(" Default ")]
                          )
                        : _vm._e(),
                      node.isRootNode
                        ? _c("v-icon", [_vm._v("business")])
                        : !node.isLeaf && node.raw.info.LogoURL
                        ? _c("v-img", {
                            attrs: {
                              src: node.raw.info.LogoURL,
                              contain: true,
                              position: "right",
                              "max-height": "30",
                            },
                          })
                        : !node.isLeaf
                        ? _c("v-icon", [_vm._v("branding_watermark")])
                        : node.isLeaf && node.raw.info.fileURL
                        ? _c("v-img", {
                            attrs: {
                              src: node.raw.info.fileURL,
                              contain: true,
                              position: "right",
                              "max-height": "30",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            },
          },
        ]),
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      !_vm.value && !(_vm.jobType === "US")
        ? _c(
            "v-alert",
            {
              staticClass: "pa-0 waring-maping",
              staticStyle: { border: "0px !important" },
              attrs: {
                value: true,
                color: "warning",
                icon: "priority_high",
                outline: "",
              },
            },
            [_vm._v(" Please select a Policy ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }