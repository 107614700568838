var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-snackbar",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.twilio.callAlert && !_vm.twilio.callAlert.isCallFromHari,
          expression: "twilio.callAlert && !twilio.callAlert.isCallFromHari",
        },
      ],
      staticClass: "user-call-notification",
      attrs: {
        timeout: 0,
        "multi-line": "",
        top: "",
        right: "",
        "auto-height": "",
        color: "white",
      },
      model: {
        value: _vm.snackbar,
        callback: function ($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar",
      },
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs8: "" } }, [
            _c("p", { staticClass: "mb-1" }, [
              _c("b", { staticClass: "subheadingfont" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.twilio.callAlert
                        ? _vm.twilio.callAlert.clientDetails
                        : ""
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("p", { staticClass: "mb-1", style: _vm.getColorStyle }, [
              _c("b", [
                _vm._v(
                  _vm._s(
                    _vm.twilio.callAlert
                      ? _vm.twilio.callAlert.incomingAlertReason
                      : ""
                  )
                ),
              ]),
            ]),
            _c(
              "p",
              { staticClass: "mb-1 body-1 text-green" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.twilio.callAlert
                        ? _vm.twilio.callAlert.policyDetails
                        : ""
                    ) +
                    " "
                ),
                _vm.twilio.callAlert && _vm.twilio.callAlert.isVerified
                  ? _c("v-icon", { attrs: { small: "", color: "green" } }, [
                      _vm._v("check_circle"),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("p", { staticClass: "mb-1" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.twilio.callAlert
                      ? _vm.twilio.callAlert.additionalDetails
                      : ""
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("v-flex", { staticClass: "xs4" }, [
            _c("img", {
              staticClass: "img-responsive",
              attrs: {
                src: _vm.twilio.callAlert
                  ? _vm.twilio.callAlert.policyLogoUrl
                  : "",
              },
            }),
          ]),
          _c(
            "v-flex",
            { staticClass: "text-xs-right mt-2", attrs: { xs12: "" } },
            [
              _vm.twilio.callAlert &&
              _vm.twilio.callAlert.incomingAlertType === "Callback Request"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "call-vibration",
                      staticStyle: { "border-radius": "10px" },
                      attrs: { fab: "", color: "green" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.initiateCallback.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" Start Call ")]
                  )
                : _vm._e(),
              _vm.twilio.callAlert &&
              _vm.twilio.callAlert.incomingAlertType !== "Callback Request"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "call-vibration",
                      attrs: { fab: "", color: "green" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onCallReceived.apply(null, arguments)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("call")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.twilio.callAlert && _vm.twilio.callAlert.isVerified
            ? _c("v-flex", { attrs: { xs12: "", "mt-2": "" } }, [
                _c("span", { staticClass: "auto-dpa" }, [
                  _vm._v("Customer has completed Auto DPA"),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }