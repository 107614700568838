var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-progress-circular", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.ready,
            expression: "!ready",
          },
        ],
        style: _vm.getLoaderStyle(50),
        attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
      }),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.ready,
              expression: "ready",
            },
          ],
          attrs: { row: "", wrap: "" },
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "elevation-1 pa-0" },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs3: "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "job-type-select px-3 pt-1" },
                            [
                              _c("v-select", {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  items: _vm.getJobTypes,
                                  label: "Job Type",
                                  "item-text": "jobType",
                                  "item-value": "jobType",
                                  "hide-details": "",
                                  "single-line": "",
                                  multiple: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onJobTypeChange(
                                      _vm.selectedJobType,
                                      $event
                                    )
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-list-tile-action",
                                          [
                                            _c("v-checkbox", {
                                              attrs: { color: "primary" },
                                              model: {
                                                value:
                                                  _vm.jobTypeCheckboxes[
                                                    _vm.getJobTypes.indexOf(
                                                      data.item
                                                    )
                                                  ].checked,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.jobTypeCheckboxes[
                                                      _vm.getJobTypes.indexOf(
                                                        data.item
                                                      )
                                                    ],
                                                    "checked",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "jobTypeCheckboxes[getJobTypes.indexOf(data.item)].checked",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.item.jobTypeDescription
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.selectedJobType,
                                  callback: function ($$v) {
                                    _vm.selectedJobType = $$v
                                  },
                                  expression: "selectedJobType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "mt-1" }),
                          _c(
                            "div",
                            { directives: [{ name: "bar", rawName: "v-bar" }] },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "specialization-list-content pa-3",
                                },
                                [
                                  _vm.trades && _vm.trades.length === 0
                                    ? _c(
                                        "div",
                                        { staticClass: "text-xs-center" },
                                        [_vm._v("No Data Available")]
                                      )
                                    : _vm._l(_vm.trades, function (trade) {
                                        return _c("v-checkbox", {
                                          key: trade.tradeId,
                                          staticClass: "que-checkbox",
                                          class: trade.isDeleted
                                            ? "deleted-item"
                                            : "",
                                          attrs: {
                                            "hide-details": true,
                                            label: trade.description,
                                            value: trade,
                                            color: "primary",
                                            name: "Trade",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onTradeSelectionChange(
                                                $event,
                                                trade
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.tradeList,
                                            callback: function ($$v) {
                                              _vm.tradeList = $$v
                                            },
                                            expression: "tradeList",
                                          },
                                        })
                                      }),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-3 mb-2", attrs: { xs9: "" } },
                        [
                          _c("h3", [_vm._v("Availability")]),
                          _c("v-divider", { staticClass: "mt-2" }),
                          _vm.trades && _vm.trades.length > 0
                            ? _c(
                                "v-layout",
                                {
                                  staticClass: "inblock",
                                  attrs: { wrap: "", row: "" },
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      directives: [
                                        {
                                          name: "bar",
                                          rawName: "v-bar",
                                          value: { useScrollbarPseudo: true },
                                          expression:
                                            "{ useScrollbarPseudo: true }",
                                        },
                                      ],
                                      attrs: { xs12: "" },
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "cta-content pa-3",
                                          attrs: { wrap: "" },
                                        },
                                        _vm._l(_vm.tradeList, function (trade) {
                                          return _c(
                                            "v-flex",
                                            {
                                              key: trade.tradeId,
                                              attrs: { xs12: "", "mb-3": "" },
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass:
                                                  "contractor_status",
                                                attrs: {
                                                  items:
                                                    _vm.getContractorStatusTypes,
                                                  label: "Contractor Status",
                                                  value:
                                                    _vm.getContractorStatus(
                                                      trade.tradeId
                                                    ),
                                                  "hide-details": "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onContractorStatusChange(
                                                      $event,
                                                      trade
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("TimeSlotPicker", {
                                                ref: "refTimeSlotPicker",
                                                refInFor: true,
                                                staticClass:
                                                  "contractor-availability",
                                                attrs: {
                                                  "header-text":
                                                    trade.description,
                                                  "filter-by-date": false,
                                                  "slot-type": "today",
                                                  model: trade,
                                                  "slot-items":
                                                    _vm.getTradeAvailabilityByTrade(
                                                      trade.tradeId
                                                    ),
                                                },
                                                on: {
                                                  updateSlotItems:
                                                    _vm.onSlotSelectionChange,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        "text-xs-right": "",
                                        "mr-0": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-3 mr-3",
                                          attrs: {
                                            color: "primary white--text",
                                            disabled:
                                              _vm.isLoading ||
                                              _vm.isAnyTimeSlotExists(),
                                            loading: _vm.isLoading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.save.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Save ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-snackbar",
                                    {
                                      attrs: {
                                        timeout: _vm.saveSnackbarTimeout,
                                        bottom: true,
                                        left: true,
                                      },
                                      model: {
                                        value: _vm.saveSnackbar,
                                        callback: function ($$v) {
                                          _vm.saveSnackbar = $$v
                                        },
                                        expression: "saveSnackbar",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.saveSnackbarText) + " "
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            flat: "",
                                            color: "secondary",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              _vm.saveSnackbar = false
                                            },
                                          },
                                        },
                                        [_vm._v("Close")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }