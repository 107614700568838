var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", [_vm._v(_vm._s(_vm.cardText))]),
      _vm.card && _vm.card.data["stripeAccount"]
        ? _c("v-chip", { staticClass: "ml-0 mt-3D" }, [
            _vm._v(_vm._s(_vm.card.data["stripeAccount"])),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }