var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "1200",
        persistent: "",
        "content-class": "v-dialog--scrollable full-card-width",
      },
      model: {
        value: _vm.store.show,
        callback: function ($$v) {
          _vm.$set(_vm.store, "show", $$v)
        },
        expression: "store.show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { card: "", dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Contractor Rate")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "close-rate-template-dialog",
                  attrs: { icon: "" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.store.show = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "px-3 scroll-content-dialog" },
            [
              _c(
                "v-card",
                { staticClass: "py-0" },
                [
                  _c("ContractorRate", {
                    ref: "contractorRateRef",
                    attrs: {
                      readonly: _vm.readonly,
                      "from-job-completion-screen": true,
                      "all-contractor-rate-package-list": _vm.store.rates,
                      "contractor-id": _vm.contractorId,
                      "rate-package-id": _vm.store.ratePackageId,
                    },
                    on: {
                      "update:allContractorRatePackageList": function ($event) {
                        return _vm.$set(_vm.store, "rates", $event)
                      },
                      "update:all-contractor-rate-package-list": function (
                        $event
                      ) {
                        return _vm.$set(_vm.store, "rates", $event)
                      },
                      "update:ratePackageId": function ($event) {
                        return _vm.$set(_vm.store, "ratePackageId", $event)
                      },
                      "update:rate-package-id": function ($event) {
                        return _vm.$set(_vm.store, "ratePackageId", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }