var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isLoading
    ? _c(
        "div",
        [
          _c("v-progress-circular", {
            style: _vm.getLoaderStyle(70),
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
    : _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "grey--text text--darken-3" }, [
              _vm._v("Customer To Pay Summary"),
            ]),
          ]),
          _c(
            "div",
            [
              _vm.payments.length
                ? _c(
                    "v-flex",
                    [
                      _c(
                        "v-expansion-panel",
                        { attrs: { value: _vm.openAccordion, expand: "" } },
                        _vm._l(_vm.payments, function (payment, index) {
                          return _c(
                            "v-expansion-panel-content",
                            {
                              key: `payments-${index}`,
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-flex",
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass:
                                                  "expansion-heading",
                                                attrs: {
                                                  "justify-space-between": "",
                                                  "align-center": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { column: "" } },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "header-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.humanizeString(
                                                              payment.type
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "subheader-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getFormattedDate(
                                                              payment.createdDate
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "h5",
                                                  { staticClass: "pr-5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getFormattedCurrency(
                                                          payment.amount
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-chip",
                                                  {
                                                    staticClass: "status-chip",
                                                    attrs: {
                                                      small: "",
                                                      color:
                                                        payment.status ===
                                                          "Success" ||
                                                        payment.status ===
                                                          "Paid"
                                                          ? "green"
                                                          : payment.status ===
                                                            "Failed"
                                                          ? "red"
                                                          : "blue",
                                                      "text-color": "white",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(payment.status)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-flex",
                                { staticClass: "px-4 pb-4" },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "expansion-heading",
                                      attrs: {
                                        "justify-space-between": "",
                                        "align-center": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { column: "" } },
                                        [
                                          payment.cardLastFourDigits
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 detail-text",
                                                },
                                                [
                                                  _vm._v("Payment Card: "),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        payment.cardLastFourDigits
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "p",
                                            { staticClass: "mb-0 detail-text" },
                                            [
                                              _vm._v("Payment Type: "),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.humanizeString(
                                                      payment.paymentType
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "mb-0 detail-text" },
                                            [
                                              _vm._v("Description: "),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(payment.description)
                                                ),
                                              ]),
                                            ]
                                          ),
                                          payment.notes
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 detail-text",
                                                },
                                                [
                                                  _vm._v("Notes: "),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(payment.notes)
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }