var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "jobCompletePreview inner-content" },
    [
      !_vm.isLoaded
        ? _c("v-progress-circular", {
            style: _vm.getLoaderStyle(70),
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          })
        : _c(
            "v-layout",
            { staticClass: "px-2 pt-3", attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-3" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "", "px-2": "" } }, [
                            _c(
                              "div",
                              { staticClass: "my-3" },
                              [
                                _vm.job.status === "Completed"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs-center successfull-msg center-content",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mb-3",
                                            attrs: { color: "green" },
                                          },
                                          [_vm._v("check_circle")]
                                        ),
                                        _c("h1", { staticClass: "pb-4" }, [
                                          _vm._v("Congratulations !"),
                                        ]),
                                        _c("h3", { staticClass: "pb-4" }, [
                                          _vm._v(
                                            "Your job is successfully completed."
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.job.status === "Cancelled"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs-center successfull-msg center-content",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mb-3",
                                            attrs: { color: "error" },
                                          },
                                          [_vm._v("cancel")]
                                        ),
                                        _c("h3", { staticClass: "pb-4" }, [
                                          _vm._v("Your job is cancelled."),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("v-divider"),
                                _c(
                                  "v-layout",
                                  { attrs: { "py-2": "", wrap: "" } },
                                  [
                                    _vm.job.createdAt
                                      ? _c(
                                          "v-flex",
                                          {
                                            staticClass: "small-text",
                                            attrs: { xs6: "", "mb-2": "" },
                                          },
                                          [
                                            _c("b", [_vm._v("Start Time:")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getFormattedDate(
                                                    _vm.job.createdAt
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.job.jobCompletedAtUtc
                                      ? _c(
                                          "v-flex",
                                          {
                                            staticClass: "small-text",
                                            attrs: { xs6: "", "mb-2": "" },
                                          },
                                          [
                                            _c("b", [_vm._v("End Time:")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getFormattedDate(
                                                    _vm.job.jobCompletedAtUtc
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.getJobDurationTime
                                      ? _c(
                                          "v-flex",
                                          {
                                            staticClass: "small-text",
                                            attrs: { xs12: "", "mb-2": "" },
                                          },
                                          [
                                            _c("b", [_vm._v("Duration:")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.getJobDurationTime) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", "mb-2": "" } },
                                      [
                                        _vm.canSeePromoterScore &&
                                        _vm.job.jobType === "HE"
                                          ? _c("v-divider")
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.canSeePromoterScore &&
                                    _vm.job.jobType === "HE"
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "elevation-0",
                                                attrs: {
                                                  "card-box-shadow-none": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { wrap: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs12: "" } },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            staticClass:
                                                              "mt-1 mb-1",
                                                            attrs: {
                                                              row: "",
                                                              wrap: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs12: "",
                                                                  "mb-1": "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "v-sup",
                                                                  },
                                                                  [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "Recommendation:"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm.job
                                                                  .recommendationScore !==
                                                                null
                                                                  ? _c(
                                                                      "star-rating",
                                                                      {
                                                                        staticClass:
                                                                          "d-inline-block pl-2",
                                                                        attrs: {
                                                                          "star-size": 20,
                                                                          rating:
                                                                            _vm
                                                                              .job
                                                                              .recommendationScore /
                                                                            2,
                                                                          "max-rating": 5,
                                                                          "active-color":
                                                                            "#e7711b",
                                                                          increment: 0.1,
                                                                          "fixed-points": 1,
                                                                          "read-only": true,
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs12: "",
                                                                  "mb-1": "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "v-sup",
                                                                  },
                                                                  [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "Service Quality:"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm.job
                                                                  .serviceQualityScore !==
                                                                null
                                                                  ? _c(
                                                                      "star-rating",
                                                                      {
                                                                        staticClass:
                                                                          "d-inline-block pl-2",
                                                                        attrs: {
                                                                          "star-size": 20,
                                                                          rating:
                                                                            _vm
                                                                              .job
                                                                              .serviceQualityScore /
                                                                            2,
                                                                          "max-rating": 5,
                                                                          "active-color":
                                                                            "#e7711b",
                                                                          increment: 0.1,
                                                                          "fixed-points": 1,
                                                                          "read-only": true,
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs12: "",
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Quality Comments:"
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.job
                                                                        .qualityComments
                                                                        ? _vm
                                                                            .job
                                                                            .qualityComments
                                                                        : ""
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.getIsRecordExists
                                  ? _c("v-divider")
                                  : _vm._e(),
                                _vm._l(
                                  _vm.getEmergencyList,
                                  function (emergency) {
                                    return _c(
                                      "v-layout",
                                      {
                                        key: emergency.id,
                                        class: _vm.getIsRecordExists
                                          ? "left-content-wrap pb-2 wrap mt-3"
                                          : "",
                                      },
                                      [
                                        _vm.getEmergencyWiseAfterJobPicture(
                                          emergency.typeId
                                        ).length > 0
                                          ? _c(
                                              "v-flex",
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass: "d-flex",
                                                    attrs: {
                                                      xs12: "",
                                                      "mb-2": "",
                                                    },
                                                  },
                                                  [
                                                    _c("h3", [
                                                      _vm._v(
                                                        _vm._s(
                                                          emergency.detailDescription
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "signechar-img",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: _vm.getCustomerSignature(
                                                              emergency.typeId
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c("PictureUpload", {
                                                      attrs: {
                                                        "job-id": _vm.jobId,
                                                        "picture-list":
                                                          _vm.getEmergencyWiseAfterJobPicture(
                                                            emergency.typeId
                                                          ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _vm.job &&
                                    !_vm.isJobTypeSI &&
                                    _vm.getContractorInvoiceDetails &&
                                    _vm.getContractorInvoiceDetails.length > 0
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c(
                                              "h4",
                                              { staticClass: "mb-2 mt-3" },
                                              [_vm._v("Contractor Cost")]
                                            ),
                                            _c(
                                              "v-expansion-panel",
                                              {
                                                staticClass:
                                                  "data-accordion elevation-0",
                                              },
                                              [
                                                _vm._l(
                                                  _vm.getContractorInvoiceDetails,
                                                  function (
                                                    contractorInvoiceDetail,
                                                    i
                                                  ) {
                                                    return [
                                                      contractorInvoiceDetail.invoiceDetail
                                                        ? _c(
                                                            "v-expansion-panel-content",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "grey lighten-5 mb-3 elevation-1 contractorInvoiceDetails",
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "align-center",
                                                                  attrs: {
                                                                    slot: "header",
                                                                    wrap: "",
                                                                  },
                                                                  slot: "header",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs10: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b",
                                                                        {
                                                                          staticClass:
                                                                            "primary--text mt-1 d-inline-block",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.getContractorCompanyName(
                                                                                  contractorInvoiceDetail
                                                                                    .contractorAppointedDetailIds[0]
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " ( " +
                                                                              _vm._s(
                                                                                _vm.getEmergencyTypeDescription(
                                                                                  contractorInvoiceDetail.contractorAppointedDetailIds
                                                                                )
                                                                              ) +
                                                                              " ) "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm.showContractorRateButton(
                                                                        contractorInvoiceDetail.status,
                                                                        contractorInvoiceDetail.ratePackageId
                                                                      )
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "ml-1",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      top: "",
                                                                                    },
                                                                                  scopedSlots:
                                                                                    _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key: "activator",
                                                                                          fn: function ({
                                                                                            on,
                                                                                          }) {
                                                                                            return [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                _vm._g(
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ma-0 view-contractor-rate",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        icon: "",
                                                                                                        small:
                                                                                                          "",
                                                                                                      },
                                                                                                    on: {
                                                                                                      click:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          $event.stopPropagation()
                                                                                                          return _vm.openContractorRateDialog(
                                                                                                            contractorInvoiceDetail.ratePackageId,
                                                                                                            contractorInvoiceDetail.contractorId
                                                                                                          )
                                                                                                        },
                                                                                                    },
                                                                                                  },
                                                                                                  on
                                                                                                ),
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "primary--text view-rate-icon",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          small:
                                                                                                            "",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "rate_review"
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                        },
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "View Rate Detail"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: "",
                                                                        "text-xs-left":
                                                                          "",
                                                                        "text-lg-right":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b",
                                                                        {
                                                                          staticClass:
                                                                            "primary--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.getFormatedCurrency(
                                                                                  contractorInvoiceDetail
                                                                                    .invoiceDetail
                                                                                    .materialCost +
                                                                                    contractorInvoiceDetail
                                                                                      .invoiceDetail
                                                                                      .labourCost +
                                                                                    contractorInvoiceDetail.additionalCharge
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              contractorInvoiceDetail.invoiceDetail
                                                                ? _c(
                                                                    "v-card",
                                                                    {
                                                                      staticClass:
                                                                        "grey lighten-5",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card-text",
                                                                        [
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  xs12: "",
                                                                                  "text-xs-right":
                                                                                    "",
                                                                                  "history-price":
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b",
                                                                                {
                                                                                  staticClass:
                                                                                    "grey--text text--darken-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Labour"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " : "
                                                                              ),
                                                                              _c(
                                                                                "b",
                                                                                {
                                                                                  class:
                                                                                    !contractorInvoiceDetail
                                                                                      .invoiceDetail
                                                                                      .overrideLabourCostReason
                                                                                      ? "primary--text"
                                                                                      : "secondary--text",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          bottom:
                                                                                            "",
                                                                                        },
                                                                                      scopedSlots:
                                                                                        _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key: "activator",
                                                                                              fn: function ({
                                                                                                on,
                                                                                              }) {
                                                                                                return [
                                                                                                  _c(
                                                                                                    "v-badge",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          overlap:
                                                                                                            "",
                                                                                                          color:
                                                                                                            "grey small-size-badge darken-2",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-avatar",
                                                                                                        _vm._g(
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                size: 35,
                                                                                                              },
                                                                                                          },
                                                                                                          on
                                                                                                        ),
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                _vm.getFormatedCurrency(
                                                                                                                  contractorInvoiceDetail
                                                                                                                    .invoiceDetail
                                                                                                                    .labourCost
                                                                                                                )
                                                                                                              ) +
                                                                                                              " "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.getOverwriteReason(
                                                                                                  contractorInvoiceDetail
                                                                                                    .invoiceDetail
                                                                                                    .overrideLabourCostReason
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm.canOverwriteContractorCost(
                                                                                contractorInvoiceDetail.status
                                                                              )
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ma-0 btn-overrideLabourCost",
                                                                                      attrs:
                                                                                        {
                                                                                          icon: "",
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openOverWriteCostDialog(
                                                                                              contractorInvoiceDetail
                                                                                                .invoiceDetail
                                                                                                .labourCost,
                                                                                              contractorInvoiceDetail,
                                                                                              _vm
                                                                                                .invoiceCostTypeEnum[
                                                                                                _vm
                                                                                                  .invoiceCostTypeEnum
                                                                                                  .Labour
                                                                                              ]
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticClass:
                                                                                            "primary--text",
                                                                                          attrs:
                                                                                            {
                                                                                              small:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "edit"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  xs12: "",
                                                                                  "text-xs-right":
                                                                                    "",
                                                                                  "history-price":
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b",
                                                                                {
                                                                                  staticClass:
                                                                                    "grey--text text--darken-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Material"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " : "
                                                                              ),
                                                                              _c(
                                                                                "b",
                                                                                {
                                                                                  class:
                                                                                    !contractorInvoiceDetail
                                                                                      .invoiceDetail
                                                                                      .overrideMaterialCostReason
                                                                                      ? "primary--text"
                                                                                      : "secondary--text",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          bottom:
                                                                                            "",
                                                                                        },
                                                                                      scopedSlots:
                                                                                        _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key: "activator",
                                                                                              fn: function ({
                                                                                                on,
                                                                                              }) {
                                                                                                return [
                                                                                                  _c(
                                                                                                    "v-badge",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          overlap:
                                                                                                            "",
                                                                                                          color:
                                                                                                            "grey small-size-badge darken-2",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-avatar",
                                                                                                        _vm._g(
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                size: 35,
                                                                                                              },
                                                                                                          },
                                                                                                          on
                                                                                                        ),
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                _vm.getFormatedCurrency(
                                                                                                                  contractorInvoiceDetail
                                                                                                                    .invoiceDetail
                                                                                                                    .materialCost
                                                                                                                )
                                                                                                              ) +
                                                                                                              " "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.getOverwriteReason(
                                                                                                  contractorInvoiceDetail
                                                                                                    .invoiceDetail
                                                                                                    .overrideMaterialCostReason
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm.canOverwriteContractorCost(
                                                                                contractorInvoiceDetail.status
                                                                              )
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ma-0 btn-overrideMaterialCost",
                                                                                      attrs:
                                                                                        {
                                                                                          icon: "",
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openOverWriteCostDialog(
                                                                                              contractorInvoiceDetail
                                                                                                .invoiceDetail
                                                                                                .materialCost,
                                                                                              contractorInvoiceDetail,
                                                                                              _vm
                                                                                                .invoiceCostTypeEnum[
                                                                                                _vm
                                                                                                  .invoiceCostTypeEnum
                                                                                                  .Material
                                                                                              ]
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticClass:
                                                                                            "primary--text",
                                                                                          attrs:
                                                                                            {
                                                                                              small:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "edit"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  xs12: "",
                                                                                  "text-xs-right":
                                                                                    "",
                                                                                  "history-price":
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b",
                                                                                {
                                                                                  staticClass:
                                                                                    "grey--text text--darken-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Travel"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " : "
                                                                              ),
                                                                              _c(
                                                                                "b",
                                                                                {
                                                                                  class:
                                                                                    !contractorInvoiceDetail.overrideAdditionalChargeReason
                                                                                      ? "primary--text"
                                                                                      : "secondary--text",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          bottom:
                                                                                            "",
                                                                                        },
                                                                                      scopedSlots:
                                                                                        _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key: "activator",
                                                                                              fn: function ({
                                                                                                on,
                                                                                              }) {
                                                                                                return [
                                                                                                  _c(
                                                                                                    "v-badge",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          overlap:
                                                                                                            "",
                                                                                                          color:
                                                                                                            "grey small-size-badge darken-2",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-avatar",
                                                                                                        _vm._g(
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                size: 35,
                                                                                                              },
                                                                                                          },
                                                                                                          on
                                                                                                        ),
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                _vm.getFormatedCurrency(
                                                                                                                  contractorInvoiceDetail.additionalCharge
                                                                                                                )
                                                                                                              ) +
                                                                                                              " "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.getOverwriteReason(
                                                                                                  contractorInvoiceDetail.overrideAdditionalChargeReason
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm.canOverwriteContractorCost(
                                                                                contractorInvoiceDetail.status
                                                                              )
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ma-0 btn-overrideAdditionalCharge",
                                                                                      attrs:
                                                                                        {
                                                                                          icon: "",
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openOverWriteCostDialog(
                                                                                              contractorInvoiceDetail.additionalCharge,
                                                                                              contractorInvoiceDetail,
                                                                                              _vm
                                                                                                .invoiceCostTypeEnum[
                                                                                                _vm
                                                                                                  .invoiceCostTypeEnum
                                                                                                  .Travel
                                                                                              ]
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticClass:
                                                                                            "primary--text",
                                                                                          attrs:
                                                                                            {
                                                                                              small:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "edit"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          contractorInvoiceDetail.disputedReason
                                                                            ? _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      xs12: "",
                                                                                      "mt-2":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "my-2",
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          contractorInvoiceDetail.disputedReason
                                                                            ? _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      xs12: "",
                                                                                      "red--text":
                                                                                        "",
                                                                                      "text--darken-2":
                                                                                        "",
                                                                                      "mt-2":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Dispute Reason"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          contractorInvoiceDetail.disputedReason
                                                                            ? _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      xs12: "",
                                                                                      "mt-1":
                                                                                        "",
                                                                                      "grey--text":
                                                                                        "",
                                                                                      "text--darken-2":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        contractorInvoiceDetail.disputedReason
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.getClientInvoiceDetail &&
                                    _vm.getClientInvoiceDetail.length > 0
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c(
                                              "h4",
                                              { staticClass: "mb-2 mt-3" },
                                              [_vm._v("Client Cost")]
                                            ),
                                            _vm.job && !_vm.isJobTypeSI
                                              ? [
                                                  _c(
                                                    "v-expansion-panel",
                                                    {
                                                      staticClass:
                                                        "data-accordion elevation-0",
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.getClientInvoiceDetail,
                                                        function (
                                                          clientInvoiceDetail,
                                                          i
                                                        ) {
                                                          return [
                                                            clientInvoiceDetail.invoiceDetail
                                                              ? _c(
                                                                  "v-expansion-panel-content",
                                                                  {
                                                                    key: i,
                                                                    staticClass:
                                                                      "grey lighten-5 mb-3 elevation-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        staticClass:
                                                                          "align-center",
                                                                        attrs: {
                                                                          slot: "header",
                                                                          wrap: "",
                                                                        },
                                                                        slot: "header",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                xs10: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b",
                                                                              {
                                                                                staticClass:
                                                                                  "primary--text mt-1 d-inline-block",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      clientInvoiceDetail.policyName
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm.showClientRateButton(
                                                                              clientInvoiceDetail.status,
                                                                              clientInvoiceDetail.ratePackageId
                                                                            )
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "ml-1",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-tooltip",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            top: "",
                                                                                          },
                                                                                        scopedSlots:
                                                                                          _vm._u(
                                                                                            [
                                                                                              {
                                                                                                key: "activator",
                                                                                                fn: function ({
                                                                                                  on,
                                                                                                }) {
                                                                                                  return [
                                                                                                    _c(
                                                                                                      "v-btn",
                                                                                                      _vm._g(
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "ma-0 view-client-rate",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              icon: "",
                                                                                                              small:
                                                                                                                "",
                                                                                                            },
                                                                                                          on: {
                                                                                                            click:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                $event.stopPropagation()
                                                                                                                return _vm.openClientRateDialog(
                                                                                                                  clientInvoiceDetail.ratePackageId
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        on
                                                                                                      ),
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "primary--text view-rate-icon",
                                                                                                            attrs:
                                                                                                              {
                                                                                                                small:
                                                                                                                  "",
                                                                                                              },
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "rate_review"
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ]
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                            null,
                                                                                            true
                                                                                          ),
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "View Rate Detail"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                xs2: "",
                                                                                "text-xs-left":
                                                                                  "",
                                                                                "text-lg-right":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b",
                                                                              {
                                                                                staticClass:
                                                                                  "primary--text",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.getFormatedCurrency(
                                                                                        _vm.getTotalClientCost(
                                                                                          clientInvoiceDetail.id
                                                                                        )
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    clientInvoiceDetail.invoiceDetail
                                                                      ? _c(
                                                                          "v-card",
                                                                          {
                                                                            staticClass:
                                                                              "grey lighten-5",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-card-text",
                                                                              [
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        xs12: "",
                                                                                        "text-xs-right":
                                                                                          "",
                                                                                        "history-price":
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "b",
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text text--darken-2",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Labour"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " : "
                                                                                    ),
                                                                                    _c(
                                                                                      "b",
                                                                                      {
                                                                                        class:
                                                                                          !clientInvoiceDetail
                                                                                            .invoiceDetail
                                                                                            .overrideLabourValueReason
                                                                                            ? "primary--text"
                                                                                            : "secondary--text",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-tooltip",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                bottom:
                                                                                                  "",
                                                                                              },
                                                                                            scopedSlots:
                                                                                              _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key: "activator",
                                                                                                    fn: function ({
                                                                                                      on,
                                                                                                    }) {
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-badge",
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                overlap:
                                                                                                                  "",
                                                                                                                color:
                                                                                                                  "grey small-size-badge darken-2",
                                                                                                              },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-avatar",
                                                                                                              _vm._g(
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      size: 35,
                                                                                                                    },
                                                                                                                },
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  " " +
                                                                                                                    _vm._s(
                                                                                                                      _vm.getFormatedCurrency(
                                                                                                                        clientInvoiceDetail
                                                                                                                          .invoiceDetail
                                                                                                                          .labourValue
                                                                                                                      )
                                                                                                                    ) +
                                                                                                                    " "
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ]
                                                                                                    },
                                                                                                  },
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              ),
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm.getOverwriteReason(
                                                                                                        clientInvoiceDetail
                                                                                                          .invoiceDetail
                                                                                                          .overrideLabourValueReason
                                                                                                      )
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm.canOverwriteClientCost(
                                                                                      clientInvoiceDetail.status
                                                                                    )
                                                                                      ? _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            staticClass:
                                                                                              "ma-0 btn-overrideLabourForClient",
                                                                                            attrs:
                                                                                              {
                                                                                                icon: "",
                                                                                                small:
                                                                                                  "",
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.openOverWriteCostDialog(
                                                                                                    clientInvoiceDetail
                                                                                                      .invoiceDetail
                                                                                                      .labourValue,
                                                                                                    clientInvoiceDetail,
                                                                                                    _vm
                                                                                                      .invoiceCostTypeEnum[
                                                                                                      _vm
                                                                                                        .invoiceCostTypeEnum
                                                                                                        .Labour
                                                                                                    ]
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "primary--text",
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "edit"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        xs12: "",
                                                                                        "text-xs-right":
                                                                                          "",
                                                                                        "history-price":
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "b",
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text text--darken-2",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Material"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " : "
                                                                                    ),
                                                                                    _c(
                                                                                      "b",
                                                                                      {
                                                                                        class:
                                                                                          !clientInvoiceDetail
                                                                                            .invoiceDetail
                                                                                            .overrideMaterialValueReason
                                                                                            ? "primary--text"
                                                                                            : "secondary--text",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-tooltip",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                bottom:
                                                                                                  "",
                                                                                              },
                                                                                            scopedSlots:
                                                                                              _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key: "activator",
                                                                                                    fn: function ({
                                                                                                      on,
                                                                                                    }) {
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-badge",
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                overlap:
                                                                                                                  "",
                                                                                                                color:
                                                                                                                  "grey small-size-badge darken-2",
                                                                                                              },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-avatar",
                                                                                                              _vm._g(
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      size: 35,
                                                                                                                    },
                                                                                                                },
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  " " +
                                                                                                                    _vm._s(
                                                                                                                      _vm.getFormatedCurrency(
                                                                                                                        clientInvoiceDetail
                                                                                                                          .invoiceDetail
                                                                                                                          .materialValue
                                                                                                                      )
                                                                                                                    ) +
                                                                                                                    " "
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ]
                                                                                                    },
                                                                                                  },
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              ),
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm.getOverwriteReason(
                                                                                                        clientInvoiceDetail
                                                                                                          .invoiceDetail
                                                                                                          .overrideMaterialValueReason
                                                                                                      )
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm.canOverwriteClientCost(
                                                                                      clientInvoiceDetail.status
                                                                                    )
                                                                                      ? _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            staticClass:
                                                                                              "ma-0 btn-overrideMaterialForClient",
                                                                                            attrs:
                                                                                              {
                                                                                                icon: "",
                                                                                                small:
                                                                                                  "",
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.openOverWriteCostDialog(
                                                                                                    clientInvoiceDetail
                                                                                                      .invoiceDetail
                                                                                                      .materialValue,
                                                                                                    clientInvoiceDetail,
                                                                                                    _vm
                                                                                                      .invoiceCostTypeEnum[
                                                                                                      _vm
                                                                                                        .invoiceCostTypeEnum
                                                                                                        .Material
                                                                                                    ]
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "primary--text",
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "edit"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        xs12: "",
                                                                                        "text-xs-right":
                                                                                          "",
                                                                                        "history-price":
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "b",
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text text--darken-2",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Handling Fee"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " : "
                                                                                    ),
                                                                                    _c(
                                                                                      "b",
                                                                                      {
                                                                                        class:
                                                                                          !clientInvoiceDetail
                                                                                            .invoiceDetail
                                                                                            .overrideHandlingFeeReason
                                                                                            ? "primary--text"
                                                                                            : "secondary--text",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-tooltip",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                bottom:
                                                                                                  "",
                                                                                              },
                                                                                            scopedSlots:
                                                                                              _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key: "activator",
                                                                                                    fn: function ({
                                                                                                      on,
                                                                                                    }) {
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-badge",
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                overlap:
                                                                                                                  "",
                                                                                                                color:
                                                                                                                  "grey small-size-badge darken-2",
                                                                                                              },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-avatar",
                                                                                                              _vm._g(
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      size: 35,
                                                                                                                    },
                                                                                                                },
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  " " +
                                                                                                                    _vm._s(
                                                                                                                      _vm.getFormatedCurrency(
                                                                                                                        clientInvoiceDetail
                                                                                                                          .invoiceDetail
                                                                                                                          .handlingFee
                                                                                                                      )
                                                                                                                    ) +
                                                                                                                    " "
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ]
                                                                                                    },
                                                                                                  },
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              ),
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm.getOverwriteReason(
                                                                                                        clientInvoiceDetail
                                                                                                          .invoiceDetail
                                                                                                          .overrideHandlingFeeReason
                                                                                                      )
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm.canOverwriteClientCost(
                                                                                      clientInvoiceDetail.status
                                                                                    )
                                                                                      ? _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            staticClass:
                                                                                              "ma-0 btn-overrideHandlingFeeForClient",
                                                                                            attrs:
                                                                                              {
                                                                                                icon: "",
                                                                                                small:
                                                                                                  "",
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.openOverWriteCostDialog(
                                                                                                    clientInvoiceDetail
                                                                                                      .invoiceDetail
                                                                                                      .handlingFee,
                                                                                                    clientInvoiceDetail,
                                                                                                    _vm
                                                                                                      .invoiceCostTypeEnum[
                                                                                                      _vm
                                                                                                        .invoiceCostTypeEnum
                                                                                                        .HandlingFee
                                                                                                    ]
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "primary--text",
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "edit"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    class:
                                                                                      _vm.canOverwriteClientCost(
                                                                                        clientInvoiceDetail.status
                                                                                      )
                                                                                        ? "other-fees"
                                                                                        : "other-fees-responsive",
                                                                                    attrs:
                                                                                      {
                                                                                        xs12: "",
                                                                                        "text-xs-right":
                                                                                          "",
                                                                                        "history-price":
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "b",
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text text--darken-2",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Other Fee"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " : "
                                                                                    ),
                                                                                    _c(
                                                                                      "b",
                                                                                      {
                                                                                        staticClass:
                                                                                          "primary--text",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  _vm.getFormatedCurrency(
                                                                                                    _vm.getOtherFeesTotal(
                                                                                                      clientInvoiceDetail.id
                                                                                                    )
                                                                                                  )
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                clientInvoiceDetail.disputedReason
                                                                                  ? _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            xs12: "",
                                                                                            "mt-2":
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-divider",
                                                                                          {
                                                                                            staticClass:
                                                                                              "my-2",
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                                clientInvoiceDetail.disputedReason
                                                                                  ? _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            xs12: "",
                                                                                            "red--text":
                                                                                              "",
                                                                                            "text--darken-2":
                                                                                              "",
                                                                                            "mt-2":
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "b",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Dispute Reason"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                clientInvoiceDetail.disputedReason
                                                                                  ? _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            xs12: "",
                                                                                            "mt-1":
                                                                                              "",
                                                                                            "grey--text":
                                                                                              "",
                                                                                            "text--darken-2":
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              clientInvoiceDetail.disputedReason
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.job &&
                                            _vm.isJobTypeSI &&
                                            _vm.getClientInvoiceDetailSI
                                              ? [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        wrap: "",
                                                        grey: "",
                                                        "pa-2": "",
                                                        "lighten-4": "",
                                                        "mb-2": "",
                                                        "primary--text": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs10: "" } },
                                                        [
                                                          _c(
                                                            "b",
                                                            {
                                                              staticClass:
                                                                "mt-1 d-inline-block pb-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .getClientInvoiceDetailSI
                                                                      .policyName
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs2: "",
                                                            "text-xs-left": "",
                                                            "text-lg-right": "",
                                                            "pt-1": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b",
                                                            {
                                                              class: !_vm
                                                                .getClientInvoiceDetailSI
                                                                .invoiceDetail
                                                                .overrideLabourValueReason
                                                                ? "primary--text"
                                                                : "secondary--text",
                                                            },
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "span",
                                                                                _vm._g(
                                                                                  {},
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    {
                                                                                      staticClass:
                                                                                        "primary--text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.getFormatedCurrency(
                                                                                              _vm
                                                                                                .getClientInvoiceDetailSI
                                                                                                .invoiceDetail
                                                                                                .labourValue
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      1408975094
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.getOverwriteReason(
                                                                            _vm
                                                                              .getClientInvoiceDetailSI
                                                                              .invoiceDetail
                                                                              .overrideLabourValueReason
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.canOverwriteClientCost(
                                                            _vm
                                                              .getClientInvoiceDetailSI
                                                              .status
                                                          )
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "ma-0 btn-si-overrideLabourForClient",
                                                                  attrs: {
                                                                    icon: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openOverWriteCostDialog(
                                                                          _vm
                                                                            .getClientInvoiceDetailSI
                                                                            .invoiceDetail
                                                                            .labourValue,
                                                                          _vm.getClientInvoiceDetailSI,
                                                                          _vm
                                                                            .invoiceCostTypeEnum[
                                                                            _vm
                                                                              .invoiceCostTypeEnum
                                                                              .Labour
                                                                          ]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "primary--text",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "edit"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.job && !_vm.isJobTypeSI
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c(
                                              "h4",
                                              { staticClass: "mb-2 mt-2" },
                                              [_vm._v("CTP")]
                                            ),
                                            _c(
                                              "v-layout",
                                              {
                                                attrs: {
                                                  wrap: "",
                                                  grey: "",
                                                  "pa-2": "",
                                                  "lighten-4": "",
                                                  "mb-2": "",
                                                  "primary--text": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs10: "" } },
                                                  [
                                                    _c(
                                                      "b",
                                                      {
                                                        staticClass:
                                                          "mt-1 d-inline-block pt-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Total Paid Amount:"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs2: "",
                                                      "text-xs-left": "",
                                                      "text-lg-center": "",
                                                      "pt-1": "",
                                                    },
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getTotalPaidAmount
                                                              ? _vm.getFormatedCurrency(
                                                                  _vm.getTotalPaidAmount
                                                                )
                                                              : _vm.getFormatedCurrency(
                                                                  0
                                                                )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.showInvoiceButton
                                  ? _c(
                                      "div",
                                      { staticClass: "text-xs-right pt-4" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "mr-0 mb-0 btn-invoice",
                                            attrs: {
                                              color: "primary",
                                              disabled:
                                                _vm.isUpdateInvoiceLoading ||
                                                _vm.getClientInvoiceDetail.filter(
                                                  (c) =>
                                                    c.status.toString() ===
                                                    _vm.clientInvoiceStatus[
                                                      _vm.clientInvoiceStatus
                                                        .Accepted
                                                    ]
                                                ).length ===
                                                  _vm.getClientInvoiceDetail
                                                    .length,
                                              loading:
                                                _vm.isUpdateInvoiceLoading,
                                            },
                                            on: {
                                              click: _vm.updateInvoiceStatus,
                                            },
                                          },
                                          [_vm._v(" Create Sales Order ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "450",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.showOverwriteCostDialog,
            callback: function ($$v) {
              _vm.showOverwriteCostDialog = $$v
            },
            expression: "showOverwriteCostDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [
                    _vm._v(
                      " Overwrite " +
                        _vm._s(
                          _vm.job && _vm.job.jobType !== "SI"
                            ? _vm.invoiceCostType
                            : ""
                        ) +
                        " Cost "
                    ),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onCancelOverwriteCost.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "mb-2": "", "primary--text": "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "prepenpound-icon grey lighten-3 px-2" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "primary--text headlinefont title pound-icon",
                            },
                            [_vm._v("£  ")]
                          ),
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "min_value:0|decimal:2",
                                expression: "'min_value:0|decimal:2'",
                              },
                            ],
                            attrs: {
                              "prepend-icon": "close",
                              name: "input-1",
                              label: _vm.invoiceCostType + " Cost Overwrite",
                              "data-vv-scope": "frmJobComplete",
                              "data-vv-name": "Cost",
                              "error-messages": _vm.errors.collect("Cost"),
                            },
                            model: {
                              value: _vm.overwriteCost,
                              callback: function ($$v) {
                                _vm.overwriteCost = $$v
                              },
                              expression: "overwriteCost",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-textarea", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              label: "Description",
                              required: "",
                              "data-vv-scope": "frmJobComplete",
                              "data-vv-name": "Description",
                              "error-messages":
                                _vm.errors.collect("Description"),
                              name: "description",
                            },
                            model: {
                              value: _vm.overwriteReason,
                              callback: function ($$v) {
                                _vm.overwriteReason = $$v
                              },
                              expression: "overwriteReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-cancelOverwriteCost",
                      attrs: { color: "primary", flat: "flat" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onCancelOverwriteCost.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 btn-save",
                      attrs: {
                        color: "primary",
                        disabled:
                          _vm.isLoading ||
                          !_vm.overwriteCost ||
                          _vm.overwriteCost === 0,
                        loading: _vm.isLoading,
                      },
                      on: { click: _vm.onOverwriteInvoiceCost },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.clientRateTemplateDialog && _vm.clientRatePackageList.length > 0
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "1200",
                persistent: "",
                "content-class": "v-dialog--scrollable full-card-width",
              },
              model: {
                value: _vm.clientRateTemplateDialog,
                callback: function ($$v) {
                  _vm.clientRateTemplateDialog = $$v
                },
                expression: "clientRateTemplateDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Client Rate")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "close-rate-template-dialog",
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.clientRateTemplateDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 scroll-content-dialog" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "py-0" },
                        [
                          _c("ClientRate", {
                            ref: "clientRateRef",
                            attrs: {
                              "from-job-completion-screen": true,
                              "all-client-rate-package-list":
                                _vm.clientRatePackageList,
                              "policy-id":
                                _vm.job && _vm.job.policyScheduleId
                                  ? _vm.job.policyScheduleId
                                  : "",
                              "rate-package-id": _vm.ratePackageId,
                              "selected-rate-package-in-policy":
                                _vm.selectedRatePackage,
                              "show-rate-template-in-dialog": true,
                            },
                            on: {
                              "update:allClientRatePackageList": function (
                                $event
                              ) {
                                _vm.clientRatePackageList = $event
                              },
                              "update:all-client-rate-package-list": function (
                                $event
                              ) {
                                _vm.clientRatePackageList = $event
                              },
                              "update:ratePackageId": function ($event) {
                                _vm.ratePackageId = $event
                              },
                              "update:rate-package-id": function ($event) {
                                _vm.ratePackageId = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("ContractorRatePackageList", {
        attrs: { readonly: false, "contractor-id": _vm.contractorId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }